import { Component, Input, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
@Component({
  selector: 'preview-tour360',
  templateUrl: './tour360.component.html',
  styles: [
  ]
})
export class Tour360Component implements OnInit {
  @Input() details: any;
  threeSixtyUrl: any = ""
  submoduleName: string = ""
  constructor(private sanitizer: DomSanitizer) { }

  ngOnChanges() {
    this.threeSixtyUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.details.url_360)
    this.submoduleName = this.details.submodule_name
  }

  ngOnInit(): void {
    this.threeSixtyUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.details.url_360)
    this.submoduleName = this.details.submodule_name
  }

  addHeading(value) {
    if (!(new RegExp('^(?=.*[#])').test(value))) {
      return `### ${value}`
    } else {
      return value
    }
  }

}
