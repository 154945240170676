import { Component } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
/***** Services ****/
import { CommonService } from './common/service/common.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
})

export class AppComponent {
  title = 'Operator Panel';
  constructor(private commonService: CommonService, private router: Router) {
    if (this.commonService.isBrowser()) {
      this.router.events.subscribe(event => {
        if (event instanceof NavigationEnd) {
          window.scrollTo(0, 0);
        }
      });
    }
  }
}
