<div class="preview-section upload-video-preview align-center">
    <markdown class="variable-binding" [data]="addHeading(submoduleName)"></markdown>
    <div class="preview-content">
        <ng-container *ngIf="uploadvideo">
            <iframe *ngIf="uploadvideo.resource_type=='youtube'" width="100%" height="240"
                [src]="sanatize('https://www.youtube.com/embed/',uploadvideo.resource_id)" title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen></iframe>
            <iframe *ngIf="uploadvideo.resource_type=='vimeo'" width="100%" height="240"
                [src]="sanatize('https://player.vimeo.com/video/',uploadvideo.resource_id)" title="Vimeo video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen></iframe>
            <video *ngIf="uploadvideo.resource_type=='source'" id="sourceVideo1" width="100%" height="240" controls>
                <source [src]="uploadvideo.resource_id1" type="video/mp4">
            </video>
        </ng-container>
    </div>
</div>