<div class="preview-section course-feedback-preview">
    <markdown class="variable-binding" [data]="addHeading(submoduleName)"></markdown>
    <div class="preview-content" style="height: 86%; overflow: auto;">
        <div class="raring-section">
            <ng-container *ngFor="let question of questionsArr; let i =index">
                <div class="rating-item" *ngIf="question.question_type==='rating'">
                    <markdown class="variable-binding" [data]="question.question"></markdown>
                    <rating [(ngModel)]="rate" [max]="max" [readonly]="isReadonly" (keyup)="confirmSelection($event)">
                    </rating>
                </div>
                <div class="rating-item" *ngIf="question.question_type==='single'">
                    <markdown class="variable-binding" [data]="question.question"></markdown>
                    <div class="custom-radio vertical radio-lg">
                        <label *ngFor="let option of question.tempOptionArr; let j =index" for="radiooption{{i}}{{j}}"
                            class="">
                            <input type="radio" name="radiooption{{i}}" id="radiooption{{i}}{{j}}" autocomplete="off" />
                            <span></span>
                            <markdown class="variable-binding" [data]="option.name"></markdown>
                        </label>
                    </div>
                </div>
                <div class="rating-item" *ngIf="question.question_type==='multiple'">
                    <markdown class="variable-binding" [data]="question.question"></markdown>
                    <div class="custom-checkbox vertical">
                        <label *ngFor="let option of question.tempOptionArr; let j =index" for="checkoption{{i}}{{j}}">
                            <input type="checkbox" id="checkoption{{i}}{{j}}" name="">
                            <span></span>
                            <markdown class="variable-binding" [data]="option.name"></markdown>
                        </label>
                    </div>
                </div>
                <div class="rating-item" *ngIf="question.question_type==='text'">
                    <markdown class="variable-binding" [data]="question.question"></markdown>
                    <textarea class="form-control secondary-control" name="" id=""></textarea>
                </div>
            </ng-container>
        </div>
        <!-- <div class="radio-select-section">
            <div class="rating-item">
                <h6>How would you rate the MDU Training Course content?</h6>
                <div class="custom-radio vertical radio-lg">
                    <label for="radiooption1" class="">
                        <input type="radio" name="radiooption1" id="radiooption1" autocomplete="off" />
                        <span></span>
                        Option 1
                    </label>
                    <label for="radiooption2" class="">
                        <input type="radio" name="radiooption1" id="radiooption2" autocomplete="off" />
                        <span></span>
                        Option 2
                    </label>
                </div>
            </div>
            <div class="rating-item">
                <h6>How would you rate the interactive design of the training?</h6>
                <div class="custom-radio vertical radio-lg">
                    <label for="radiooption3" class="">
                        <input type="radio" name="radiooption2" id="radiooption3" autocomplete="off" />
                        <span></span>
                        Option 3
                    </label>
                    <label for="radiooption4" class="">
                        <input type="radio" name="radiooption2" id="radiooption4" autocomplete="off" />
                        <span></span>
                        Option 4
                    </label>
                </div>
            </div>
            <div class="rating-item">
                <h6>Do you feel confident in delivering an MDU order after completing this training?</h6>
                <div class="custom-radio vertical radio-lg">
                    <label for="radiooption5" class="">
                        <input type="radio" name="radiooption3" id="radiooption5" autocomplete="off" />
                        <span></span>
                        Option 5
                    </label>
                    <label for="radiooption6" class="">
                        <input type="radio" name="radiooption3" id="radiooption6" autocomplete="off" />
                        <span></span>
                        Option 6
                    </label>
                </div>
            </div>
        </div>
        <div class="checkbox-select-section">
            <div class="rating-item">
                <h6>How would you rate the MDU Training Course content?</h6>
                <div class="custom-checkbox vertical">
                    <label for="checkoption1">
                        <input type="checkbox" id="checkoption1" name="">
                        <span></span> Option 1
                    </label>
                    <label for="checkoption2">
                        <input type="checkbox" id="checkoption2" name="">
                        <span></span> Option 2
                    </label>
                </div>
            </div>
            <div class="rating-item">
                <h6>How would you rate the interactive design of the training?</h6>
                <div class="custom-checkbox vertical">
                    <label for="checkoption3">
                        <input type="checkbox" id="checkoption3" name="">
                        <span></span> Option 3
                    </label>
                    <label for="checkoption4">
                        <input type="checkbox" id="checkoption4" name="">
                        <span></span> Option 4
                    </label>
                </div>
            </div>
            <div class="rating-item">
                <h6>Do you feel confident in delivering an MDU order after completing this training?</h6>
                <div class="custom-checkbox vertical">
                    <label for="checkoption5">
                        <input type="checkbox" id="checkoption5" name="">
                        <span></span> Option 5
                    </label>
                    <label for="checkoption6">
                        <input type="checkbox" id="checkoption6" name="">
                        <span></span> Option 6
                    </label>
                </div>
            </div>
        </div>
        <div class="rating-box">
            <h6>How would you rate the SkillsBase App platform in general?</h6>
            <textarea class="form-control secondary-control" name="" id=""></textarea>
        </div> -->
    </div>
</div>