<style>
    .response-image {
        width: 97px;
        height: 97px;
        border-radius: 10%;
    }

    .response-image img {
        object-fit: cover;
        width: 97px;
        height: 97px;
    }

    table.dataTable.dtr-inline.collapsed>tbody>tr[role="row"]>td:first-child:before,
    table.dataTable.dtr-inline.collapsed>tbody>tr[role="row"]>th:first-child:before {
        top: 21px;
    }

    table.dataTable.dtr-inline.collapsed>tbody>tr[role="row"]>td:first-child,
    table.dataTable.dtr-inline.collapsed>tbody>tr[role="row"]>th:first-child {
        position: absolute;
        padding-left: 30px;
        cursor: pointer;
    }

    table.dataTable.dtr-inline.collapsed>tbody>tr[role="row"]>td:first-child:before {
        top: 9px;
    }

    table.dataTable tbody tr {
        background: #ffffff;
    }

    table.dataTable.no-footer {
        border-bottom: none !important;
    }

    @media screen and (max-width: 991px) {
        .btn .minW-md-0 {
            min-width: unset;
        }
    }
</style>
<!-- BEGIN : Show Skeleton Loader -->
<div class="content-wrapper" [hidden]="!showSkeletonLoader1">
    <div class="animated fadeIn" [ngClass]="type === 'assignCoursesList' ? '': 'card'">
        <div class="card-header">
            <div class="row">
                <div class="col-md-6"></div>
                <div class="col-md-6">
                    <ngx-skeleton-loader count="1"></ngx-skeleton-loader>
                </div>
            </div>
        </div>
        <div class="card-body">
            <ngx-skeleton-loader count="5"></ngx-skeleton-loader>
        </div>
        <div class="card-footer">
            <div class="row">
                <div class="col-md-6"></div>
                <div class="col-md-6">
                    <ngx-skeleton-loader count="1"></ngx-skeleton-loader>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- END : Show Skeleton Loader -->
<div class="content-wrapper datatable-main" [hidden]="showSkeletonLoader1">
    <div class="animated fadeIn" [ngClass]="type === 'assignCoursesList' ? '': 'card'">
        <!-- TABLE HEADER START-->
        <div class="card-body">
            <!-- Data Table Start -->
            <!-- {{tableConfig.cols | json}} -->

            <!-- filter/download/search buttons -->
            <div class="user-listing-filterOptions" *ngIf="this.conditions.showTableHeader">
                <div class="row">
                    <div class="col-sm-6">
                        <div class="top-filter-btn" *ngIf="this.conditions.showFilter">
                            <button (click)="openFilter()" class="btn btn-default-filter" type="button">
                                <span class="d-none d-sm-none d-lg-inline-block">Filter</span>
                            </button>
                        </div>
                        <!-- apply active , inactive and delete on mutiple users -->
                        <div class="d-inline-block"
                            *ngIf="this.conditions.showApplyStatus && this.selectedUserList.length">
                            <ng-container>
                                <form #statusForm="ngForm" (ngSubmit)="submitStatusForm(statusForm,statusFormData)"
                                    class="w-150">
                                    <ng-select class="sm-ng-select ng-select-line select-option" [items]="statusList"
                                        bindLabel="statusFormData?.status" bindValue="statusFormData.status"
                                        placeholder="Actions" [clearable]='false' [searchable]='false'
                                        [dropdownPosition]="'auto'" #status="ngModel" name="status"
                                        [(ngModel)]="statusFormData.status" required
                                        (change)="submitStatusForm(statusForm,statusFormData)">
                                    </ng-select>
                                </form>
                                <!-- apply active , inactive and delete on mutiple users -->
                            </ng-container>
                        </div>
                    </div>
                    <div class="col-sm-6">
                        <div *ngIf="this.conditions.search">
                            <div class="search-input-wrapper">
                                <form>
                                    <div class="form-group mb-0">
                                        <em class="bx bx-search"></em>
                                        <input type="text" class="form-control icon-search" placeholder="Search..."
                                            name="search" #search="ngModel" [(ngModel)]="searchText" autocomplete="off"
                                            (keyup.enter)="searchData($event)">
                                        <div class="remove-icon"><em class="bx bx-x" *ngIf="searchText"
                                                (click)="removeSearch()"></em></div>
                                    </div>
                                </form>
                            </div>
                        </div>
                        <!-- <ng-container *ngIf="this.conditions.showButton && !conditions.modal">
                            <button type="button" class="nav-link  btn btn-primary listlink"
                                [routerLink]="[this.conditions.showButton.routerLink]">
                                <em class="fa fa-plus" aria-hidden="true"></em>
                                <span>{{this.conditions.showButton.buttonName}}</span>
                            </button>
                        </ng-container> -->
                    </div>
                </div>
                <div class="filter-data-dropdown" *ngIf="this.conditions.showFilter" [ngClass]="status ? 'open' : ''">
                    <div class="custom-dropdown-menu">
                        <div class="row">
                            <ng-container *ngFor="let i of tableConfig?.cols">
                                <div class="col-lg-4 col-sm-6 col-12" *ngIf="i.filter">
                                    <div class="form-group">
                                        <label>{{i.colName}}</label>
                                        <ng-select
                                            *ngIf="i.type === 'switch' || (type === 'jobsReponsesList' && i.type === 'text' && i.colName == 'Status')"
                                            class="ng-select-line" [items]="statusFilterlist" bindValue="value"
                                            bindLabel="status" placeholder="Select {{i.colName}}" [clearable]='false'
                                            [searchable]="i.colName == 'Status' ? false : true"
                                            [multiple]="i.colName == 'Status' ? false : true"
                                            [dropdownPosition]="'auto'" [closeOnSelect]="true"
                                            (focus)="getSatutsFilterlistData(i.colFieldname)" [(ngModel)]="i.value"
                                            required>
                                        </ng-select>
                                        <input type="text" [name]="i.colFieldname" class="form-control"
                                            [id]="i.colFieldname" *ngIf="i.isDateRange" autocomplete="off"
                                            bsDaterangepicker [(ngModel)]="i.value"
                                            [bsConfig]="{ rangeInputFormat : 'YYYY-MM-DD',dateInputFormat: 'YYYY-MM-DD', showWeekNumbers: false, containerClass: 'theme-green' }"
                                            placeholder="Select Dates"
                                            (bsValueChange)="dateCreated(i.colFieldname,$event)" />

                                        <input type="text" [name]="i.colFieldname" class="form-control"
                                            [id]="i.colFieldname" *ngIf="i.isDate || i.isDateTime" autocomplete="off"
                                            bsDatepicker [(ngModel)]="i.value"
                                            [bsConfig]="{ dateInputFormat: 'YYYY-MM-DD', showWeekNumbers: false, containerClass: 'theme-green' }"
                                            placeholder="Select Date" />

                                        <input class="form-control" autocomplete="off"
                                            [hidden]="i.colName === 'Status' || i.isDate || i.isDateTime"
                                            placeholder="Enter {{i.colName}}" [(ngModel)]="i.value"
                                            (keydown)="(i.colFieldname === 'assigned_course' || i.colFieldname === 'total_subdata') ? restrictKeyPressService.AllowNumbers($event) : null">
                                    </div>
                                </div>
                            </ng-container>
                        </div>
                        <hr>
                        <div class="row filter-btn">
                            <div class="col-md-12 text-right">
                                <!-- [disabled]="disableApplyFilter" -->
                                <button type="button" class="btn btn-primary" (click)="applyFilter()">Apply
                                    Filter</button>
                                <button type="button" class="btn btn-outline btn-primary" (click)="resetFilter()"
                                    [disabled]="disableResetFilter">Reset</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="datatable-inner">
                <!-- column hide show-->
                <ng-container *ngIf="this.conditions.showColumnHide">
                    <button type="button" class="settings-button" data-toggle="collapse"
                        data-target="#columnVisiblityFilter" aria-expanded="false" aria-controls="columnVisiblityFilter"
                        [attr.aria-expanded]="!columnVisibilityOptions" (click)="tooglecolumnVisibilityFilter()"
                        tooltip="Column Options" placement="bottom">
                        <em class='bx bx-cog'></em>
                    </button>

                    <!-- ON COLLAPSE COLUMN HIDE SHOW -->
                    <div class="collapse custom-options" [collapse]="!columnVisibilityOptions" [isAnimated]="true"
                        id="columnVisiblityFilter">
                        <div class="cp-row" *ngFor="let i of tableConfig?.cols" [ngClass]="(i.columnVisibility)?'':''">
                            <ng-container *ngIf="i.columnVisibility">
                                <div class="custom-checkbox sm">
                                    <label>
                                        <input type="checkbox" name="status" [(ngModel)]="i.isVisible"
                                            (change)="changeCheckBoxValue()" [disabled]="i.disable">
                                        <span></span>
                                        {{i.colName}}
                                    </label>
                                </div>
                            </ng-container>
                        </div>
                        <!-- <hr />
                        <div class="row text-right filter-btn">
                            <div class="col-md-12"> 
                                <button type="button" class="btn btn-primary" (click)="resetColumnVisible()">Reset</button>
                                <button type="submit" class="btn btn-primary"
                                    (click)=" CallAPIchangeColumnVisibility(tableConfig?.cols)">Save</button>
                                <button type="button" class="btn btn-primary" (click)="selectAllColumnVisible()">Select
                                    All</button>                            
                            </div>
                        </div> -->
                    </div>
                    <!-- ON COLLAPSE COLUMN HIDE SHOW -->

                </ng-container>
                <!-- column hide show-->
                <div class="table-responsive">
                    <!-- datatable -->
                    <table [dtOptions]="dtOptions" [dtTrigger]="dtTrigger" sortable-table (sorted)="onSorted($event)"
                        class="custom-table table table-striped" id="table" *ngIf="tableData?.data"
                        aria-describedby="table" width="100%">
                        <thead>
                            <tr>
                                <th [hidden]="!i.isVisible" [sortable-column]="i" [displaySortIcon]="i.sort"
                                    *ngFor="let i of tableConfig?.cols" id="th1"
                                    [ngClass]="{'text-truncate': i.type == 'html', 'align-center':i.type == 'symbol' }">
                                    <ng-container *ngIf="i.type!=='multipleSelection' && i.type!=='switch'">
                                        {{i.colName}}
                                    </ng-container>
                                    <ng-container *ngIf="i.type=='switch'">{{i.colName}}</ng-container>
                                    <div *ngIf="i.type=='multipleSelection'" class="custom-checkbox">
                                        <ng-container *ngIf="!isCheckable; else basicMultipleSelection">
                                            <label><input type="checkbox" checked disabled><span></span></label>
                                        </ng-container>
                                        <ng-template #basicMultipleSelection>
                                            <label><input type="checkbox" name="checkbox" [(ngModel)]="i.checkbox"
                                                    (change)="selectAllUser(i)"><span></span></label>
                                        </ng-template>
                                    </div>

                                </th>
                            </tr>
                        </thead>

                        <tbody>
                            <ng-container *ngIf="tableData.data.length">
                                <tr class="animated fadeIn" *ngFor="let i of tableData?.data;let y=index;">
                                    <!-- <td></td> -->
                                    <td [ngClass]="j.type=='action'?'d-flex action-td':''" [hidden]="!j.isVisible"
                                        *ngFor="let j of tableConfig?.cols">
                                        <div *ngIf="j.type=='multipleSelection'" class="custom-checkbox">
                                            <ng-container *ngIf="i.disabled; else basicMultipleSelection">
                                                <label><input type="checkbox" name="checkbox" [(ngModel)]="i.checkbox"
                                                        disabled><span></span></label>
                                            </ng-container>
                                            <ng-template #basicMultipleSelection>
                                                <label><input type="checkbox" name="checkbox" [(ngModel)]="i.checkbox"
                                                        (change)="selectMultipleUser(i)"><span></span></label>
                                            </ng-template>
                                        </div>
                                        <div *ngIf="j.type=='switch'">
                                            <label class="switch">
                                                <input type="checkbox" class="success" [(ngModel)]="i[j.colFieldname]"
                                                    (click)="changeStatus(i)">
                                                <span class="slider round"></span>
                                            </label>
                                        </div>
                                        <ng-container *ngIf="j.type=='action'">
                                            <ng-container *ngIf="!j.status || i[j.status] == 'FAILED'">
                                                <div class="btn-group" dropdown container="body">
                                                    <a href="javascript:void(0)" id="action-basic" dropdownToggle
                                                        type="button" class="action-button"
                                                        aria-controls="dropdown-action">
                                                        <em class='bx bx-dots-vertical-rounded'></em>
                                                    </a>
                                                    <ul id="dropdown-action" *dropdownMenu
                                                        class="dropdown-menu action-dropdown" role="menu"
                                                        aria-labelledby="action-basic">
                                                        <li *ngFor="let k of tableConfig?.actions"
                                                            class="action-icon menuitem">
                                                            <a class="dropdown-item" href="javascript:void(0)"
                                                                (click)="performAction(k,i)">
                                                                <ng-container *ngIf="k.type === 'icon'">
                                                                    <em [class]="k.class" aria-hidden="true"
                                                                        [tooltip]="k.tooltip"></em>
                                                                    {{k.buttonTitle}}
                                                                </ng-container>
                                                                <div *ngIf="k.type === 'button'">
                                                                    <ng-container>
                                                                        <button type="button" [class]="k.class"
                                                                            (click)="performAction(k,i)">{{k.buttonTitle}}</button>
                                                                    </ng-container>
                                                                </div>
                                                            </a>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </ng-container>
                                        </ng-container>
                                        <div *ngIf="j.type =='symbol'" class="align-center">
                                            <span *ngIf="i[j.colFieldname] === true">&#10003;</span>
                                            <span *ngIf="i[j.colFieldname] === false">&#9747;</span>
                                        </div>
                                        <div *ngIf="j.type=='text'">
                                            <div class="dt-icon-with-name" [ngClass]="j.colFieldname == 'name' ? 'name-with-image' : (i[j.colFieldname] == 'Pending' ? 'dt-status pending' : ((i[j.colFieldname] == 'Approved' || i[j.colFieldname] == 'PASSED') ? 'dt-status approved' : ((i[j.colFieldname] == 'Rejected' || i[j.colFieldname] == 'FAILED') ? 'dt-status rejected' : (i[j.colFieldname] == 'TROPHY' ? 'bx bx-trophy' : ''))))"
                                                [ngStyle]="{'font-size':i[j.colFieldname] == 'TROPHY' ? '24px': '', 'color': i[j.colFieldname] == 'TROPHY' ? '#13A8B6' : '', 'margin-left': i[j.colFieldname] == 'TROPHY' ? '20px' : ''}">
                                                <ng-container *ngIf="j.colFieldname == 'name'">
                                                    <div class="short-name">{{ i[j.colFieldname].charAt(0) }}</div>
                                                </ng-container>
                                                <ng-container
                                                    *ngIf="j.colFieldname == 'resource_type'">
                                                    <div class="ngs-icon"><em
                                                            [ngClass]="(i[j.colFieldname] == 'image' ? 'bx bxs-image' : (i[j.colFieldname] == 'source' ? 'bx bxs-videos' : (i[j.colFieldname] == 'vimeo' ? 'bx bxl-vimeo' : (i[j.colFieldname] == 'PDF' ? 'bx bxs-file-pdf' : 'bx bxl-youtube'))))"></em></div>
                                                    <!-- <div class="ngs-icon" *ngIf="i[j.colFieldname] == 'source'"><em class='bx bxs-videos'></em></div>
                                                            <div class="ngs-icon" *ngIf="i[j.colFieldname] == 'vimeo'"><em class='bx bxl-vimeo'></em></em></div>
                                                                <div class="ngs-icon" *ngIf="i[j.colFieldname] == 'source'"><em
                                                                    class="bx bxl-youtube"></em></div> -->
                                                </ng-container>
                                                <ng-container *ngIf="i[j.colFieldname] !== 'TROPHY'">
                                                    <div class="type-name" [ngClass]="j.colFieldname == 'name' ? 'column-name' : ''">
                                                        {{j.isObj
                                                        ?
                                                        i[j.colFieldname][j.isObj] : (j.isDate ? (i[j.colFieldname] |
                                                        date:
                                                        (j.isDateTime ? 'yyyy-MM-dd HH:mm:ss' : 'yyyy-MM-dd') ) :
                                                        i[j.colFieldname] ? i[j.colFieldname] :
                                                        '--')}}</div>
                                                </ng-container>
                                            </div>
                                        </div>
                                        <div *ngIf="j.type=='html'" [innerHTML]="i[j.colFieldname]"></div>
                                    </td>
                                </tr>
                            </ng-container>
                            <tr *ngIf="!tableData.data.length">
                                <td class="text-center w-100 p-2" [attr.colspan]="tableConfig.cols.length">
                                    No data available.
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <!-- Data Table End -->
            </div>
        </div>
    </div>
    <!-- TABLE FOOTER START-->
    <div class="card-footer" *ngIf="this.conditions.showTableFooter">
        <div class="custom-pagination d-flex align-items-center justify-content-between" *ngIf="tableData.data?.length">
            <div class="pagination-left">
                <ng-select class="ng-select-line" (change)="selectItemPerPage(itemsPerPage)" [items]="itemsPerPageList"
                    placeholder="Select" [(ngModel)]="itemsPerPage" [clearable]='false' [searchable]='false'
                    [dropdownPosition]="'auto'" [labelForId]="tableData.type">
                </ng-select>
                <span class="record-count ml-3">per page</span>
            </div>
            <div class="pagination-list">
                <pagination [itemsPerPage]='itemsPerPage' #customPagination [totalItems]="total"
                    (pageChanged)="pagination($event)" [maxSize]="5" previousText="Prev" nextText="Next"
                    firstText="&laquo;" lastText="&raquo;">
                </pagination>
            </div>
        </div>
        <!-- Pagination End -->
    </div>
    <!-- TABLE FOOTER END-->
</div>