<div class="main-content-area">
    <header id="header" class="header-section fixed-top">
        <nav class="navbar d-flex navbar-expand">
            <div class="brand-logo">
                <a href="javascript:;" class="skillbase-ic">
                    <img src="assets/images/logo-ic.svg" alt="Logo" width="33" height="33">
                </a>
                <a href="javascript:;">
                    <img src="assets/images/logo-text.svg" alt="Logo" width="114" height="21">
                </a>
            </div>
            <ul class="navbar-nav flex-row ml-auto d-flex">
                <li class="nav-item nav-search">
                    <a class="nav-link nav-link-search" href="javascript:;" (click)="openSearch()">
                        <em class="bx bx-search"></em>
                    </a>
                    <div class="search-input">
                        <div class="search-box">
                            <div class="search-input-icon"><em class="bx bx-search primary"></em></div>
                            <input class="input" type="text" placeholder="Search..." tabindex="-1"
                                data-search="template-search">
                            <div class="search-input-close" (click)="closeSearch()"><em class="bx bx-x"></em></div>
                        </div>
                        <ul class="search-list">
                            <li>
                                <a>
                                    <span class="bx bx-error-circle"></span>
                                    <span>No results found.</span>
                                </a>
                            </li>
                        </ul>
                    </div>
                </li>
                <li class="nav-item dropdown dropdown-notification">
                    <a [routerLink]="[URLConstants.NOTIFICATIONS]" class="nav-link nav-link-label" type="button"
                        id="notificationDropdown" aria-controls="dropdown-animated">
                        <em class="bx bx-bell bx-tada bx-flip-horizontal"></em>
                        <span *ngIf="notificationCount!=0"
                            class="badge badge-pill badge-primary badge-up">{{notificationCount}}</span>
                    </a>
                </li>
                <li class="nav-item user-dropdown dropdown" dropdown>
                    <a class="nav-link dropdown-toggle dropdown-user-link pr-0" dropdownToggle type="button"
                        id="userDropdown" aria-controls="dropdown-animated">
                        <span class="profile-header"><img class="" [src]="photo ? photo : 'assets/images/NoProfile.png'"
                                alt="no img"></span>
                    </a>
                    <div class="dropdown-menu dropdown-menu-right" id="userDropdown" *dropdownMenu role="menu"
                        aria-labelledby="button-animated">
                        <a class="dropdown-item" [routerLink]="[URLConstants.MY_ACCOUNT]"><em
                                class='bx bx-user mr-2'></em>My Account</a>
                        <a class="dropdown-item" (click)="logout()"><em class='bx bx-log-out mr-2'></em>Logout</a>
                    </div>
                </li>
            </ul>
        </nav>
    </header>
</div>