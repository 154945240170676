// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --configuration='staging' && node server.js` replaces `environment.ts` with `environment.staging.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
    production: true,
    environmentName: 'staging',
    port: 4049,
    apiUrl: 'https://staging-api.skillsbase.io/v1/operator/',
    uploadImgUrl: 'https://skillsbase.indianic.dev/laravel-api2/public/images/',
};