import { Component, Input, OnChanges, OnInit } from '@angular/core';

@Component({
  selector: 'preview-confirmation',
  templateUrl: './confirmation.component.html',
  styles: [
  ]
})
export class ConfirmationComponent implements OnInit, OnChanges {
  @Input() details: any;
  previewData: any;
  constructor() { }

  ngOnChanges() {
    this.previewData = this.details;
  }
  ngOnInit(): void {
    this.previewData = this.details;
  }

  addhash(value) {
    if (!(new RegExp('^(?=.*[#])').test(value))) {
      return `# ${value}`
    } else {
      return value
    }
  }
  addHeading(value) {
    if (!(new RegExp('^(?=.*[#])').test(value))) {
      return `### ${value}`
    } else {
      return value
    }
  }

}
