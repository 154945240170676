<div class="preview-section quiz-preview">
    <markdown class="variable-binding" [data]="addHeading(details.submodule_name)"></markdown>
    <div class="preview-content">
        <div class="quiz-image">
            <img src="assets/images/quiz-image.jpg" alt="Quiz" />
        </div>
        <div class="quiz-content">
            <h5 class="mb-3">What must be used at PoE lead around drill hole on wall?</h5>
            <ul class="quiz-ul">
                <li style="cursor: pointer;" (click)="checked(1)" class="{{selected==1?'checked':''}}">Fire Sealant <em
                        *ngIf="selected==1" class='bx bx-check-circle'></em></li>
                <li style="cursor: pointer;" (click)="checked(2)" class="{{selected==2?'checked':''}}">Putty <em
                        *ngIf="selected==2" class='bx bx-check-circle'></em></li>
                <li style="cursor: pointer;" (click)="checked(3)" class="{{selected==3?'checked':''}}">General Sealant
                    <em *ngIf="selected==3" class='bx bx-check-circle'></em>
                </li>
                <li style="cursor: pointer;" (click)="checked(4)" class="{{selected==4?'checked':''}}">Nothing the hole
                    is negligiblein size so
                    no sealant require <em *ngIf="selected==4" class='bx bx-check-circle'></em></li>
            </ul>
        </div>
    </div>
</div>