<form #catForm="ngForm">
    <div class="modal-header">
        <h4 class="modal-title"> Add Category</h4>
    </div>
    <div class="modal-body">
        <input type="text" class="form-control" name="name" #name="ngModel" [(ngModel)]="category.name"
            [ngClass]="((submitted || name.touched) && name.invalid)?'has-danger':''" required>
        <div *ngIf="(submitted || name.touched) && name.invalid" class="has-error">
            {{showErrorService.showError('category_name', name.errors)}}
        </div>
    </div>
    <div class="modal-footer">
        <div class="text-right">
            <button type="button" class="btn btn-primary btn-outline with-icon" (click)="BsModalRef.hide()"><em
                    class="bx bx-x-circle"></em> Cancel</button>
            <button type="submit" class="btn btn-primary with-icon" (click)="addCategory(catForm)"><em
                    class='bx bx-plus-circle'></em> Add</button>
        </div>
    </div>
</form>