import { Component, HostListener, OnInit, TemplateRef } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { NavigationEnd, Router } from '@angular/router';
/************  services *************/
import { LogoutService } from 'src/app/common/service/logout.service';
import { TranslateService } from '@ngx-translate/core';
import { LocalStorageService } from '../../../common/service/local-storage.service';
import { ShowErrorService } from '../../../common/service/show-error.service';
import { CommonService } from '../../../common/service/common.service';
/************  constants *************/
import { URLConstants } from '../../../common/constants/routerLink-constants';
import { callAPIConstants } from '../../../common/constants/callAPI-constants';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html'
})

export class HeaderComponent implements OnInit {
  callAPIConstants = callAPIConstants;
  URLConstants = URLConstants;

  public dropdown: boolean = false;
  public firstname: any;
  public lastname: any;
  public photo: any;
  public theme: string = null;
  public alignment: string = null;
  public status: boolean = false;
  passwordTypeOld: boolean;
  passwordTypeNew: boolean;
  public passdata: any = {};
  public submitted = false;
  modalRef: BsModalRef;
  fieldTextType: boolean;
  notificationCount: number = 0;


  constructor(private localStorageService: LocalStorageService,
    private logoutService: LogoutService, private showErrorService: ShowErrorService,
    private router: Router, public translate: TranslateService, private commonService: CommonService,
    private modalService: BsModalService) {
    translate.addLangs(['en', 'fr']);
    translate.setDefaultLang('en');

    const browserLang = translate.getBrowserLang();
    translate.use(browserLang.match(/en|fr/) ? browserLang : 'en');

    if (this.commonService.isBrowser()) {
      this.router.events.subscribe(event => {
        if (event instanceof NavigationEnd) {
          this.commonService.callApi(this.callAPIConstants.GetNotificationCount, {}, 'get', false, false, false).then((success) => {
            if (!success.errors) {
              this.notificationCount = success.data
            }
          });
        }
      });
    }

  }

  openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template);
  }

  ngOnInit(): void {
    this.commonService.notificationCount.subscribe((data) => {
      this.notificationCount = data;
    })
  }

  @HostListener('window:scroll', [])
  onWindowScroll(event: Event) {
    if (document.body.scrollTop > 0 ||
      document.documentElement.scrollTop > 0) {
      document.getElementById('header').classList.add('sticky');
    }
    else {
      document.getElementById('header').classList.remove('sticky');
    }
  }

  toggleFieldTextTypeOld() {
    this.passwordTypeOld = !this.passwordTypeOld;
  }

  toggleFieldTextTypeNew() {
    this.passwordTypeNew = !this.passwordTypeNew;
  }

  logout() {
    this.logoutService.logout();
  }

  showHideDropdown() {
    this.dropdown = !this.dropdown;
  }

  clickEvent() {
    this.status = !this.status;
  }

  openSidebar() {
    var sidebar = Array.from(document.getElementsByClassName('sidebar') as HTMLCollectionOf<HTMLElement>);
    sidebar[0].style.display = 'block';

  }

  openSearch() {
    Array.from(document.getElementsByClassName('search-input'))[0].setAttribute('class', 'search-input search-open');
    document.getElementsByTagName('body')[0].classList.add('body-search-open');
  }

  closeSearch() {
    Array.from(document.getElementsByClassName('search-input'))[0].setAttribute('class', 'search-input');
    document.getElementsByTagName('body')[0].classList.remove('body-search-open');
  }

  ngDoCheck(): void {
    this.firstname = this.localStorageService.getToken('firstname');
    this.lastname = this.localStorageService.getToken('lastname');
    this.photo = this.localStorageService.getToken('photo');

  }

  /****************************************************************************/
}

