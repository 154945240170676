import { Component, Input, OnInit } from '@angular/core';
/************* Constants  ****************/
import { URLConstants } from 'src/app/common/constants/routerLink-constants';

@Component({
  selector: 'app-sub-header',
  templateUrl: './sub-header.component.html'
})

export class SubHeaderComponent implements OnInit {
  @Input() subHeaderData: any;
  URLConstants = URLConstants;

  ngOnInit(): void {
  }

}
