import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'preview-photo-hotspot-uploader',
  templateUrl: './phot-hotspot-uploader.component.html',
  styles: [
  ]
})
export class PhotoHotspotUploaderComponent implements OnInit {
  @Input() details: any;
  previewData: any;
  submoduleName: string = "";

  constructor() { }

  ngOnChanges() {
    this.previewData = this.details;
    this.submoduleName = this.details.submodule_name;
  }
  ngOnInit(): void {
    this.previewData = this.details;
    this.submoduleName = this.details.submodule_name;
  }
  setStyle(x, y, x2, y2) {
    let left = x + ((x2 - x) / 2);
    let top = y + ((y2 - y) / 2);

    return `left: ${left}px;top:${top}px`;
  }
  addHeading(value) {
    if (!(new RegExp('^(?=.*[#])').test(value))) {
      return `### ${value}`
    } else {
      return value
    }
  }
}
