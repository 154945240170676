<div class="preview-section happy-unhappy-fact-preview">
    <markdown *ngIf="!detailScreen" class="variable-binding" [data]="addHeading(submoduleName)"></markdown>
    <div class="preview-content" style="height: 86%; overflow: auto;">
        <div *ngIf="!detailScreen" class="happy-unhappy-listing">
            <div *ngFor="let fact of factsArr;let i=index;" class="happy-unhappy-section">
                <div class="course-item-box">
                    <div class="course-img">
                        <a href="javascript:;">
                            <img [src]="fact.thumbnail1" alt="Module">
                        </a>
                    </div>
                    <div class="course-detail-box">
                        <h6><a href="javascript:;">
                                <markdown class="variable-binding" [data]="fact.title"></markdown>
                            </a></h6>
                        <div class="course-info">
                            <div (click)="navigateToDetail(i, false)" class="happy-unhappy-box"
                                class="{{fact.happy?'happy':''}}">
                                <em class="bx bx-happy-alt"></em>
                            </div>
                            <div (click)="navigateToDetail(i, true)" class="happy-unhappy-box"
                                class="{{fact.unHappy?'unhappy':''}}">
                                <em class="bx bx-sad"></em>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div *ngIf="detailScreen" class="happy-unhappy-view">
            <div class="header-box" class="{{isUnhappy?'unhappy':'happy'}}">
                <h6 class="mb-0"><a (click)="detailScreen=false;" href="javascript:;"><em
                            class="bx bx-arrow-back"></em></a>
                    <markdown class="variable-binding" [data]="factsArr[currentIndex].title"></markdown>
                </h6>
                <!-- <h6 class="mb-0">1 / 26</h6> -->
            </div>
            <div class="content-box">
                <div class="media-box">
                    <ng-container *ngIf="!isUnhappy">
                        <iframe *ngIf="factsArr[currentIndex].happy_resource_type=='youtube'" width="100%" height="240"
                            [src]="sanatize('https://www.youtube.com/embed/',factsArr[currentIndex].happy_resource_id)"
                            title="YouTube video player" frameborder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowfullscreen></iframe>
                        <iframe *ngIf="factsArr[currentIndex].happy_resource_type=='vimeo'" width="100%" height="240"
                            [src]="sanatize('https://player.vimeo.com/video/',factsArr[currentIndex].happy_resource_id)"
                            title="Vimeo video player" frameborder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowfullscreen></iframe>
                        <video *ngIf="factsArr[currentIndex].happy_resource_type=='source'" id="sourceVideo1"
                            width="100%" height="240" controls>
                            <source [src]="factsArr[currentIndex].happy_resource_id1" type="video/mp4">
                        </video>
                    </ng-container>
                    <ng-container *ngIf="isUnhappy">
                        <iframe *ngIf="factsArr[currentIndex].unhappy_resource_type=='youtube'" width="100%"
                            height="240"
                            [src]="sanatize('https://www.youtube.com/embed/',factsArr[currentIndex].unhappy_resource_id)"
                            title="YouTube video player" frameborder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowfullscreen></iframe>
                        <iframe *ngIf="factsArr[currentIndex].unhappy_resource_type=='vimeo'" width="100%" height="240"
                            [src]="sanatize('https://player.vimeo.com/video/',factsArr[currentIndex].unhappy_resource_id)"
                            title="Vimeo video player" frameborder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowfullscreen></iframe>
                        <video *ngIf="factsArr[currentIndex].unhappy_resource_type=='source'" id="sourceVideo2"
                            width="100%" height="240" controls>
                            <source [src]="factsArr[currentIndex].unhappy_resource_id1" type="video/mp4">
                        </video>
                    </ng-container>
                </div>
                <div *ngIf="!isUnhappy" [innerHtml]="factsArr[currentIndex].happy_description" class="inner-content">
                </div>
                <div *ngIf="isUnhappy" [innerHtml]="factsArr[currentIndex].unhappy_description" class="inner-content">
                </div>
                <div class="select-options">
                    <div class="prev-arrow nav-arrow">
                        <a href="javascript:;"><em class="bx bx-arrow-back"></em></a>
                    </div>
                    <div class="happy-unhappy-select">
                        <div class="radio-box">
                            <input type="radio" id="happy" name="happyunhappy"
                                (click)="navigateToDetail(currentIndex, false)" [checked]="!isUnhappy" value="Happy">
                            <label for="happy" class="happy">
                                <em class="bx bx-happy-alt"></em>
                                <h6 class="mb-0">Happy</h6>
                            </label>
                        </div>
                        <div class="radio-box">
                            <input type="radio" id="unhappy" name="happyunhappy" [checked]="isUnhappy"
                                (click)="navigateToDetail(currentIndex, true)" value="Unhappy">
                            <label for="unhappy" class="unhappy">
                                <em class="bx bx-sad"></em>
                                <h6 class="mb-0">Unhappy</h6>
                            </label>
                        </div>
                    </div>
                    <div class="next-arrow nav-arrow">
                        <a href="javascript:;"><em class="bx bx-arrow-back bx-rotate-180"></em></a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>