import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Component({
  selector: 'preview-upload-video',
  templateUrl: './upload-video.component.html',
  styles: [
  ]
})
export class UploadVideoComponent implements OnInit, OnChanges {
  @Input() details: any;
  constructor(private sanitizer: DomSanitizer) { }
  uploadvideo: any;
  submoduleName: string = "";

  ngOnChanges() {
    this.uploadvideo = this.details.uploadvideo;
    this.submoduleName = this.details.submodule_name;
  }

  ngOnInit(): void {
    this.uploadvideo = this.details.uploadvideo;
    this.submoduleName = this.details.submodule_name;
  }

  sanatize(url, id): SafeHtml {
    let src = url + id;
    return this.sanitizer.bypassSecurityTrustResourceUrl(src)
  }

  addHeading(value) {
    if (!(new RegExp('^(?=.*[#])').test(value))) {
      return `### ${value}`
    } else {
      return value
    }
  }

}
