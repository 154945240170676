<div class="preview-section confirmation-preview align-center">
    <markdown class="variable-binding" [data]="addHeading(previewData.submodule_name)"></markdown>
    <div class="preview-content">
        <markdown class="variable-binding" [data]="addhash(previewData.title)"></markdown>
        <div class="link-box">
            <a href="javascript:;" class="btn btn-gray">
                <markdown class="variable-binding" [data]="previewData.button1_text"></markdown>
            </a>
            <a href="javascript:;" class="btn btn-primary">
                <markdown class="variable-binding" [data]="previewData.button2_text"></markdown>
            </a>
        </div>
    </div>
</div>