<div class="preview-section product-listing-preview">
    <markdown class="variable-binding" [data]="addHeading(submoduleName)"></markdown>
    <div class="preview-content" style="height: 86%; overflow: auto;">
        <ng-container *ngIf="productList">
            <div class="product-listing-item" *ngFor="let product of productList; let i=index">
                <div class="img-box">
                    <img [src]="product.image1" alt="product image">
                </div>
                <div class="content-box">
                    <markdown class="variable-binding" [data]="product.name"></markdown>
                    <p>{{product?.code}}</p>
                    <div class="dropdown" dropdown>
                        <a href="javascript:;" id="productDropdown" dropdownToggle class="dropdown-toggle"
                            aria-controls="dropdown-basic"> <em class="bx bx-dots-vertical-rounded"></em>
                        </a>
                        <ul id="dropdown-basic" *dropdownMenu class="dropdown-menu dropdown-menu-right" role="menu"
                            aria-labelledby="productDropdown">
                            <li role="menuitem"><a class="dropdown-item" href="javascript:;"><em
                                        class="bx bx-show-alt text-primary"></em> View</a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </ng-container>
    </div>
</div>