import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
/************ constants  ************/
import { callAPIConstants } from 'src/app/common/constants/callAPI-constants';
import { URLConstants } from 'src/app/common/constants/routerLink-constants';
/************ service  ************/
import { CommonService } from '../../common/service/common.service';
import { ShowErrorService } from '../../common/service/show-error.service';

@Component({
  selector: 'app-password-reset',
  templateUrl: './password-reset.component.html'
})

export class PasswordResetComponent implements OnInit {
  callAPIConstants = callAPIConstants;
  URLConstants = URLConstants;
  
  public user: any = {};
  public submitted: boolean = false;

  constructor(private router: Router, private commonService: CommonService, public showErrorService: ShowErrorService) { }

  ngOnInit(): void {
  }


  /*************************************************************
  @PURPOSE      : Forget Password
  @PARAMETERS   : form, Obj
  @RETURN       : NA
  /*************************************************************/
  onForgotPassword(form, user) {
    this.submitted = true;
    if (form.valid) {
      this.commonService.callApi(this.callAPIConstants.ForgotPassword, user, 'post', true, false).then((success) => {
        if (!success.errors) {
          window.sessionStorage.setItem('email', this.commonService.encrypt(user.email));
          this.showErrorService.popToast('success', success.extra_meta.message);
          this.router.navigate([URLConstants.VERIFY_CODE])
        } else {
          this.showErrorService.popToast('error', 'Please enter registered emailId');
        }
      })
    }
  }

}
