import { Component } from '@angular/core';
/******** plugin *********/
import { BsModalRef } from 'ngx-bootstrap/modal';
/******** constants *********/
import { callAPIConstants } from '../../../common/constants/callAPI-constants';
/******** services *********/
import { CommonService } from '../../../common/service/common.service';
import { ShowErrorService } from '../../../common/service/show-error.service';

@Component({
  selector: 'app-add-edit-category',
  templateUrl: './add-edit-category.component.html',
  styles: [
  ]
})
export class AddEditCategoryComponent {
  callAPIConstants = callAPIConstants;
  public courseId: number;

  constructor(public BsModalRef: BsModalRef, public commonService: CommonService, public showErrorService: ShowErrorService) { }

  /*********************************************************************************
  @PURPOSE      : Add category to the list
  @PARAMETERS   : form
  @RETURN       : N/A
  /*********************************************************************************/
  public category: any = {};
  public submitted: boolean = false;
  addCategory(form) {
    this.submitted = true;
    let apiName;
    let methodType;
    if (form.valid) {
      if (this.category.id) {
        apiName = this.callAPIConstants.GetCategoryById + this.category.id;
        methodType = 'put';
      } else {
        apiName = this.callAPIConstants.AddCategory;
        methodType = 'post';
      }
      this.category.training_course_id = this.courseId
      this.commonService.callApi(apiName, this.category, methodType, false, false, false).then((success) => {
        if (!success.errors) {
          this.showErrorService.popToast('success', success.extra_meta.message);
          let data = { training_course_id: this.courseId, page: 1, per_page: 10 }
          this.commonService.callApi(this.callAPIConstants.QuestionCategories, data, 'post', false, false, false).then(() => { });
          this.BsModalRef.hide();
        } else {
          this.showErrorService.popToast('error', success.errors.message);
        }
      });
    }
  }

}
