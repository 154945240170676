<div class="preview-section title-slide-preview">
    <markdown class="variable-binding" [data]="addHeading(previewData.submodule_name)"></markdown>
    <div class="preview-content">
        <div class="preview-titleslide">
            <div class="title">
                <markdown class="variable-binding" [data]="addhash(previewData.title)"></markdown>
            </div>
            <div class="subTitle">
                <markdown class="variable-binding" [data]="previewData.subtitle"></markdown>
            </div>
        </div>
        <a href="javascript:;" class="btn btn-primary">
            <markdown class="variable-binding" [data]="previewData.button_text"></markdown>
        </a>
    </div>
</div>