import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { CommonModule } from "@angular/common";
import { FormsModule } from "@angular/forms";
/**** Auth Guard ****/
import { CanAuthActivate } from "./common/TS-files/auth.guard";
/**** Component ****/
import { MainComponent } from './modules/main/main.component';
import { LoginComponent } from "./public/login/login.component";
import { ResetPasswordComponent } from "./public/reset-password/reset-password.component";
import { MasterComponent } from './modules/master/master.component';
/**** plugin ****/
import { NgOtpInputModule } from 'ng-otp-input';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input';
/**** module ****/
import { SharedModule } from './shared/shared.module';
import { PasswordResetComponent } from "./public/password-reset/password-reset.component";
import { ConfirmEmailComponent } from "./public/confirm-email/confirm-email.component";
/**** submodule preview components ****/
import { TitleSlideComponent } from './shared/preview-components/title-slide/title-slide.component';
import { Tour360Component } from './shared/preview-components/tour360/tour360.component';
import { UploadVideoComponent } from './shared/preview-components/upload-video/upload-video.component';
import { ImageGalleryComponent } from './shared/preview-components/image-gallery/image-gallery.component';
import { ProductListingComponent } from './shared/preview-components/product-listing/product-listing.component';
import { HappyUnhappyFactsComponent } from './shared/preview-components/happy-unhappy-fact/happy-unhappy-fact.component';
import { CourseFeedbackComponent } from './shared/preview-components/course-feedback/course-feedback.component';
import { VideoGuideComponent } from './shared/preview-components/video-guide/video-guide.component';
import { ImageHotspotComponent } from './shared/preview-components/image-hotspot/image-hotspot.component';
import { ConfirmationComponent } from './shared/preview-components/confirmation/confirmation.component';
import { PhotoHotspotUploaderComponent } from './shared/preview-components/phot-hotspot-uploader/phot-hotspot-uploader.component';
import { QuizComponent } from './shared/preview-components/quiz/quiz.component';
import { JobsComponent } from './shared/preview-components/jobs/jobs.component';
import { ConfirmationBoxesComponent } from './shared/preview-components/confirmation-boxes/confirmation-boxes.component';
import { NotificationsComponent } from './modules/notifications/notifications.component';


const routes: Routes = [
  { path: "", redirectTo: "login", pathMatch: "full" },
  {
    path: "login",
    component: LoginComponent,
    pathMatch: "full",
  },
  {
    path: "reset-password",
    component: ResetPasswordComponent,
    pathMatch: "full",
  },
  {
    path: "password-reset",
    component: PasswordResetComponent,
    pathMatch: "full",
  },
  {
    path: "verify-code",
    component: ConfirmEmailComponent,
    pathMatch: "full",
  },
  /**** Preview Pages routing ****/
  {
    path: "slide1",
    component: TitleSlideComponent,
    pathMatch: "full",
  },
  {
    path: "slide2",
    component: Tour360Component,
    pathMatch: "full",
  },
  {
    path: "slide3",
    component: UploadVideoComponent,
    pathMatch: "full",
  },
  {
    path: "slide4",
    component: ImageGalleryComponent,
    pathMatch: "full",
  },
  {
    path: "slide5",
    component: ProductListingComponent,
    pathMatch: "full",
  },
  {
    path: "slide6",
    component: HappyUnhappyFactsComponent,
    pathMatch: "full",
  },
  {
    path: "slide7",
    component: CourseFeedbackComponent,
    pathMatch: "full",
  },
  {
    path: "slide8",
    component: VideoGuideComponent,
    pathMatch: "full",
  },
  {
    path: "slide9",
    component: ImageHotspotComponent,
    pathMatch: "full",
  },
  {
    path: "slide10",
    component: ConfirmationComponent,
    pathMatch: "full",
  },
  {
    path: "slide11",
    component: PhotoHotspotUploaderComponent,
    pathMatch: "full",
  },
  {
    path: "slide12",
    component: QuizComponent,
    pathMatch: "full",
  },
  {
    path: "slide13",
    component: JobsComponent,
    pathMatch: "full",
  },
  {
    path: "slide14",
    component: ConfirmationBoxesComponent,
    pathMatch: "full",
  },
  {
    path: '',
    canActivate: [CanAuthActivate],
    component: MainComponent,
    children: [ { path: "notifications", component: NotificationsComponent, pathMatch: 'full' }]
  },
  {
    path: '',
    canActivate: [CanAuthActivate],
    component: MainComponent,
    children: [
      { path: 'master', component: MasterComponent, pathMatch: 'full' },
      {
        path: "dashboard",
        canActivate: [CanAuthActivate],
        loadChildren: () =>
          import("./modules/dashboard/dashboard.module").then(
            (mod) => mod.DashboardModule
          )
      },
      {
        path: "courses",
        canActivate: [CanAuthActivate],
        loadChildren: () =>
          import("./modules/courses/courses.module").then(
            (mod) => mod.CoursesModule
          )
      },
      {
        path: "users",
        canActivate: [CanAuthActivate],
        loadChildren: () =>
          import("./modules/user-management/users/users.module").then(
            (mod) => mod.UsersModule
          ),
      },
      {
        path: "groups",
        canActivate: [CanAuthActivate],
        loadChildren: () =>
          import("./modules/user-management/groups/groups.module").then(
            (mod) => mod.GroupsModule
          ),
      },
      {
        path: "product",
        canActivate: [CanAuthActivate],
        loadChildren: () =>
          import("./modules/product/product.module").then(
            (mod) => mod.ProductModule
          ),
      },
      {
        path: "resource",
        canActivate: [CanAuthActivate],
        loadChildren: () =>
          import("./modules/resources/resources.module").then(
            (mod) => mod.ResourcesModule
          ),
      },
      {
        path: "news",
        canActivate: [CanAuthActivate],
        loadChildren: () =>
          import("./modules/news/news.module").then(
            (mod) => mod.NewsModule
          ),
      },
      {
        path: "jobs",
        canActivate: [CanAuthActivate],
        loadChildren: () =>
          import("./modules/jobs/jobs.module").then(
            (mod) => mod.JobsModule
          ),
      },
      {
        path: "my-account",
        canActivate: [CanAuthActivate],
        loadChildren: () =>
          import("./modules/my-account/my-account.module").then(
            (mod) => mod.MyAccountModule
          ),
      },
      {
        path: 'my-profile',
        loadChildren: () => import('./modules/my-profile/my-profile.module')
          .then((m) => m.MyProfileModule),
      },
      {
        path: 'setting',
        loadChildren: () => import('./modules/setting/setting.module')
          .then(m => m.SettingModule)
      },
      {
        path: 'manage-roles',
        loadChildren: () => import('./modules/manage-roles/manage-roles.module')
          .then((m) => m.ManageRolesModule),
      },
      {
        path: 'manage-team',
        loadChildren: () => import('./modules/team-management/team-management.module')
          .then((m) => m.TeamManagementModule),
      },
      { path: '**', redirectTo: 'dashboard', pathMatch: 'full' },
    ],
  },
];

@NgModule({
  declarations: [
    LoginComponent,
    PasswordResetComponent,
    ResetPasswordComponent,
    ConfirmEmailComponent,
    NotificationsComponent,
    MainComponent,
    MasterComponent
  ],
  imports: [
    SharedModule,
    CommonModule,
    FormsModule,
    NgOtpInputModule,
    NgxIntlTelInputModule,
    RouterModule.forRoot(routes, { useHash: true }),
  ],
  exports: [RouterModule],
  providers: [CanAuthActivate],
})
export class AppRoutingModule { }
