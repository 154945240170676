import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'preview-happy-unhappy-facts',
  templateUrl: './happy-unhappy-fact.component.html',
  styles: [
  ]
})
export class HappyUnhappyFactsComponent implements OnInit, OnChanges {
  @Input() details: any;
  submoduleName: string = "";
  detailScreen: boolean = false;
  factsArr = [];
  isUnhappy: boolean = false
  currentIndex;

  constructor(private sanitizer: DomSanitizer) { }

  ngOnChanges() {
    this.factsArr = this.details.factsArr;
    this.submoduleName = this.details.submodule_name;
    if (this.factsArr?.length > 0) {
      this.factsArr.map((data) => {
        data.happy = false;
        data.unHappy = false;
        return data;
      })
    }
    if (this.detailScreen) {

      if (!this.isUnhappy) {
        let video: any = document.getElementById(`sourceVideo1`)
        video.load();
      } else {
        let video: any = document.getElementById(`sourceVideo2`)
        video.load();
      }
    }

  }

  ngOnInit(): void {
    this.factsArr = this.details.factsArr;
    this.submoduleName = this.details.submodule_name;
  }

  navigateToDetail(index, section) {
    this.detailScreen = true;
    this.currentIndex = index;
    this.isUnhappy = section;
    section ? this.factsArr[this.currentIndex].unHappy = true : this.factsArr[this.currentIndex].happy = true
  }

  sanatize(url, id) {
    let src = url + id;
    return this.sanitizer.bypassSecurityTrustResourceUrl(src)
  }

  addHeading(value) {
    if (!(new RegExp('^(?=.*[#])').test(value))) {
      return `### ${value}`
    } else {
      return value
    }
  }


}
