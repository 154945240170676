<aside class="sidebar">
    <div class="sidebar-inner-wrapper">
        <div class="sidebar-menu">
            <div class="team-sidebar">
                <div class="team-header">
                    <a href="javascript:;" (click)="teamResultOpen()" [ngClass]="{'active':teamMenuFlag}">
                        <span>Team</span>
                        <em class="bx bxs-chevron-right"></em>
                    </a>
                </div>
                <div class="team-result" [ngClass]="{'active':teamMenuFlag}">
                    <div class="team-name">
                        <img src="assets/images/NoProfile.png" alt="Team" width="24" height="24">
                        <span>DexGreen</span>
                    </div>
                    <div class="team-list">
                        <ul>
                            <li><a href="javascript:;"><span class="profile-name-box primary">AA</span></a></li>
                            <li><a href="javascript:;" class="profile-img-box"><img src="assets/images/NoProfile.png"
                                        alt="Team" width="32" height="32"></a></li>
                            <li><a href="javascript:;"><span class="profile-name-box secondary">AA</span></a></li>
                            <li><a href="javascript:;" class="profile-img-box"><img src="assets/images/NoProfile.png"
                                        alt="Team" width="32" height="32"></a></li>
                            <li><a href="javascript:;"><span class="profile-name-box tertiary">AA</span></a></li>
                        </ul>
                    </div>
                    <div class="add-team">
                        <a href="javascript:;"><em class="bx bx-plus-circle"></em> Add Team</a>
                    </div>
                </div>
            </div>
            <ul class="sidebar-menu-list">
                <li class="menu-list-item has-child-submenu level1" (click)="openCloseSubMenu('dashboardMenu')">
                    <a [routerLink]="[URLConstants.DASHBOARD]" class="menu-link"
                        [ngClass]="{'active': router.url.includes(URLConstants.DASHBOARD)}">
                        <em class="bx bx-bar-chart-square"></em>
                        <span class="menu-title">Dashboard</span>
                    </a>
                </li>
                <li class="menu-list-item has-child-submenu level1" [ngClass]="{'submenu-open':subCoursesMenu}">
                    <a class="menu-link" (click)="openCloseSubMenu('subCoursesMenu')"
                        [ngClass]="{'active':subCoursesMenu || router.url.includes(URLConstants.COURSES_LIST)}">
                        <em class="bx bx-news"></em>
                        <span class="menu-title">Courses</span>
                        <em class="bx bxs-chevron-right"></em>
                    </a>
                    <ul class="sidebar-menu-list sub-menu-list" [ngClass]="{'show' : subCoursesMenu}">
                        <li class="menu-list-item">
                            <a [routerLink]="[URLConstants.COURSES_LIST]" class="menu-link"
                                [ngClass]="{'active':router.url.includes(URLConstants.COURSES_LIST)}">
                                <em class="bx bx-right-arrow-alt"></em>
                                <span class="menu-title">Courses Directory</span>
                            </a>
                        </li>
                    </ul>
                </li>
                <li class="menu-list-item has-child-submenu level1" [ngClass]="{'submenu-open':subUserMenu}">
                    <a class="menu-link" (click)="openCloseSubMenu('subUserMenu')"
                        [ngClass]="{'active':subUserMenu || router.url.includes(URLConstants.USERS) || router.url.includes(URLConstants.USERS_DETAIL) || router.url.includes(URLConstants.ADD_USER) || router.url.includes(URLConstants.EDIT_USER)|| router.url.includes(URLConstants.GROUPS)}">
                        <em class="bx bx-group"></em>
                        <span class="menu-title">Users</span>
                        <em class="bx bxs-chevron-right"></em>
                    </a>
                    <ul class="sidebar-menu-list sub-menu-list" [ngClass]="{'show' : subUserMenu}">
                        <li class="menu-list-item">
                            <a [routerLink]="[URLConstants.USERS]" class="menu-link"
                                [ngClass]="{'active':router.url.includes(URLConstants.USERS) || router.url.includes(URLConstants.USERS_DETAIL) || router.url.includes(URLConstants.ADD_USER) || router.url.includes(URLConstants.EDIT_USER)}">
                                <em class="bx bx-right-arrow-alt"></em>
                                <span class="menu-title">Manage Users</span>
                            </a>
                        </li>
                        <li class="menu-list-item">
                            <a [routerLink]="[URLConstants.GROUPS]" class="menu-link"
                                [ngClass]="{'active':router.url.includes(URLConstants.GROUPS)}">
                                <em class="bx bx-right-arrow-alt"></em>
                                <span class="menu-title">Manage Groups</span>
                            </a>
                        </li>
                    </ul>
                </li>
                <!-- <li class="menu-list-item">
                    <a class="menu-link" [ngClass]="{'active':router.url.includes(URLConstants.GROUPS)}">
                        <em class="bx bx-bar-chart-alt"></em>
                        <span class="menu-title">Analytics</span>
                    </a>
                </li> -->
                <li class="menu-list-item">
                    <a [routerLink]="[URLConstants.PRODUCT]" class="menu-link"
                        [ngClass]="{'active':router.url.includes(URLConstants.PRODUCT)}">
                        <em class="bx bx-cube-alt"></em>
                        <span class="menu-title">Products</span>
                    </a>
                </li> 
                <li class="menu-list-item">
                    <a [routerLink]="[URLConstants.RESOURCES]" class="menu-link"
                        [ngClass]="{'active':router.url.includes(URLConstants.RESOURCES)}">
                        <em class="bx bx-spreadsheet"></em>
                        <span class="menu-title">Resources</span>
                    </a>
                </li>
                <!-- <li class="menu-list-item">
                    <a [routerLink]="[URLConstants.JOBS]" class="menu-link"
                        [ngClass]="{'active':router.url.includes(URLConstants.JOBS)}">
                        <em class="bx bx-briefcase-alt"></em>
                        <span class="menu-title">Jobs</span>
                    </a>
                </li>
                <li class="menu-list-item has-child-submenu level1" [ngClass]="{'submenu-open':subDirectoryMenu}">
                    <a class="menu-link" (click)="openCloseSubMenu('subDirectoryMenu')"
                        [ngClass]="{'active':subDirectoryMenu || router.url.includes(URLConstants.GROUPS)}">
                        <em class="bx bx-data"></em>
                        <span class="menu-title">Directories</span>
                        <em class="bx bxs-chevron-right"></em>
                    </a>
                    <ul class="sidebar-menu-list sub-menu-list" [ngClass]="{'show':subDirectoryMenu}">
                        <li class="menu-list-item">
                            <a class="menu-link" [ngClass]="{'active':router.url.includes(URLConstants.GROUPS)}">
                                <em class="bx bx-right-arrow-alt"></em>
                                <span class="menu-title">Product Directory</span>
                            </a>
                        </li>
                        <li class="menu-list-item">
                            <a class="menu-link" [ngClass]="{'active':router.url.includes(URLConstants.GROUPS)}">
                                <em class="bx bx-right-arrow-alt"></em>
                                <span class="menu-title">Resource Directory</span>
                            </a>
                        </li>
                        <li class="menu-list-item">
                            <a class="menu-link" [ngClass]="{'active':router.url.includes(URLConstants.GROUPS)}">
                                <em class="bx bx-right-arrow-alt"></em>
                                <span class="menu-title">Course Directory</span>
                            </a>
                        </li>
                    </ul>
                </li> -->
                <li class="menu-list-item">
                    <a [routerLink]="[URLConstants.NEWS]" class="menu-link" [ngClass]="{'active':router.url.includes(URLConstants.NEWS)}">
                        <em class="bx bx-receipt"></em>
                        <span class="menu-title">News</span>
                    </a>
                </li>
               <!-- <li class="menu-list-item">
                    <a class="menu-link" [ngClass]="{'active':router.url.includes(URLConstants.GROUPS)}">
                        <em class="bx bx-happy-alt"></em>
                        <span class="menu-title">Feedback</span>
                    </a>
                </li>
                <li class="menu-list-item">
                    <a class="menu-link" [ngClass]="{'active':router.url.includes(URLConstants.GROUPS)}">
                        <em class="bx bx-chart"></em>
                        <span class="menu-title">Reports</span>
                    </a>
                </li> -->
                <!-- <li class="menu-list-item" (click)="openCloseSubMenu('emailMenu')">
                    <a [routerLink]="[URLConstants.EMAIL_TEMPLATE_list]" class="menu-link"
                        [ngClass]="{'active':router.url.includes(URLConstants.EMAIL_TEMPLATE_list)}">
                        <em class='bx bx-envelope'></em>
                        <span class="menu-title">Email Management</span>
                    </a>
                </li> -->
                <!-- <li class="menu-list-item" (click)="openCloseSubMenu('roleMenu')">
                    <a [routerLink]="[URLConstants.MANAGE_ROLES]" class="menu-link"
                        [ngClass]="{'active':router.url.includes(URLConstants.MANAGE_ROLES)}">
                        <em class='bx bx-id-card'></em>
                        <span class="menu-title">Roles</span>
                    </a>
                </li> -->
                <li class="menu-list-item" (click)="openCloseSubMenu('teamMenu')">
                    <a [routerLink]="[URLConstants.TEAM_MEMBERS_LIST]" class="menu-link"
                        [ngClass]="{'active':router.url.includes(URLConstants.TEAM_MEMBERS_LIST)}">
                        <em class='bx bx-user-plus'></em>
                        <span class="menu-title">Team Management</span>
                    </a>
                </li>
                <li class="menu-list-item" (click)="openCloseSubMenu('accountMenu')">
                    <a [routerLink]="[URLConstants.MY_ACCOUNT]" class="menu-link"
                        [ngClass]="{'active':router.url.includes(URLConstants.MY_ACCOUNT)}">
                        <em class="bx bx-cog"></em>
                        <span class="menu-title">My Account</span>
                    </a>
                </li>
            </ul>
            <!-- <div class="upgrade-course">
                <div class="arrow-up"><em class="bx bx-up-arrow-alt"></em></div>
                <h5>Upgrade to Pro for more resources</h5>
                <a href="javascript:;" class="upgrade-btn">Upgrade</a>
            </div> -->
        </div>
    </div>
</aside>