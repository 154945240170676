import { AfterViewInit, Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { DatePipe } from '@angular/common';
import { Router } from '@angular/router';
/************** environment  ******************/
import { environment } from 'src/environments/environment';
/************** plugins  ******************/
import { DataTableDirective } from 'angular-datatables';
import Responsive from 'datatables.net-responsive';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Subject } from 'rxjs';
import { saveAs } from 'file-saver';
/************** constants  ******************/
import { callAPIConstants } from '../../../common/constants/callAPI-constants';
import { URLConstants } from '../../../common/constants/routerLink-constants';
/************** Services  ******************/
import { CommonService } from '../../../common/service/common.service';
import { RestrictKeyPressService } from '../../../common/service/restrict-key-press.service';
import { ShowErrorService } from '../../../common/service/show-error.service';
/************** interfaces  ******************/
import { BsDropdownConfig } from 'ngx-bootstrap/dropdown';
/************* Modal Component ****************/
import { AddEditCategoryComponent } from '../../modal/add-edit-category/add-edit-category.component';

@Component({
  selector: 'app-responsive-data-table',
  templateUrl: './responsive-data-table.component.html',
  styles: [],
  providers: [{ provide: BsDropdownConfig, useValue: { autoClose: false } }],

})
export class ResponsiveDataTableComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('filterForm', { static: false }) filterForm;
  callAPIConstants = callAPIConstants;
  public URLConstants = URLConstants;
  dtOptions: any = {};
  /* dtOptions: DataTables.Settings = {}; */
  dtTrigger: Subject<any> = new Subject();
  @ViewChild(DataTableDirective, { static: false })
  dtElement: DataTableDirective;
  dropdown: boolean = false;
  APIparameters: any = {};
  options: any = {};
  total: any;
  public type: any;
  public submitted = false;
  public selectedUserList: any[] = [];
  public columnVisibilityOptions = false;
  public isCheckable: boolean = true;
  public filterColumnOption = false;
  public params: any;
  public conditions: any;
  public searchText: any;
  public color: any = '';
  public filterColor: any = '#4184EF';
  showSkeletonLoader1: boolean = true;
  @Input() columns: Array<any>;
  @Input() tableSetupData: any;
  public tempData: any = [];
  public tableConfig: any = {};
  tableData = {
    cols: ['id', 'author', 'title'],
    data: [],
    rowSelected: 0,
    value: false,
    type: '',
  };
  public downloadUrl: any = 'http://universaladminapiv2.node.indianic.com/public/'
  @ViewChild('customPagination', { static: false }) customPagination: any;
  public downloadColumnsArr: any = [];
  public columnVisibilityOptions1: any;
  public columnKey: any;
  public columnsAction: any = false;
  downloadColumns: string;
  toggleDownloadButton: boolean;
  toggleFilterButton: boolean = false;
  getDowloadTemplates: string;
  downloadTemplatesArr: any = [];
  downloadFile: string;
  link: string;
  cols: any = [];
  configs: any = {};
  public colSettingCols: Array<any> = [];
  calenderData: any = {};
  status: boolean = false;
  statusDownload: boolean = false;
  statusColorPicker: boolean = false;
  statusFilterColorPicker: boolean = false;

  colors = [
    {
      hex: '#00cfdd',
      name: 'blue'
    },
    {
      hex: '#b3c0ce',
      name: 'gray'
    },
    {
      hex: '#FF5B5C',
      name: 'rose'
    },
    {
      hex: '#b87ee5',
      name: 'purple'
    },
    {
      hex: '#39DA8A',
      name: 'green'
    },
    {
      hex: '#FDAC41',
      name: 'yellow'
    },
  ];

  selectedColor: '';

  /* Action */
  public actions: any = [{ name: 'Active', value: 'Active' }, { name: 'Inactive', value: 'Inactive' }];

  selectColor(hash, col) {
    this.color = col;
    document.getElementById("downloadDropdownColor").style.backgroundColor = hash;
    this.openCloseColorDropdown();
  }

  selectFilterColor(hash, col) {
    this.filterColor = col;
    document.getElementById("filterDropdownColor").style.backgroundColor = hash;
    this.openCloseColorFilterDropdown();

  }

  active: boolean = false;

  selectedColorName: '';

  selector() {
    if (!this.selectedColor) {
      return 'Color';
    }
    else {
      return '<span style="background: ' + this.selectedColor + '"></span> ' + this.selectedColorName;
    }
  }

  setColor(color, colorName) {
    this.selectedColor = color;
    this.selectedColorName = colorName;
    this.active = false;
  }


  toggleDropdown() {
    this.active = !this.active;

  }
  /* open filter button */
  openFilter() {
    this.status = !this.status;
  }

  /* open download button */
  openDownloadButton() {
    this.statusDownload = !this.statusDownload;
  }

  /* open color dropdown */
  openCloseColorDropdown() {
    this.statusColorPicker = !this.statusColorPicker;
  }

  /* open color filter dropdown */
  openColorDropdownFilter() {
    this.statusFilterColorPicker = !this.statusFilterColorPicker
  }

  /* open color filter dropdown */
  openCloseColorFilterDropdown() {
    this.statusFilterColorPicker = !this.statusFilterColorPicker
  }

  public condition: any = '$and';
  public filteredArr: any = [];
  public submitted1: boolean = false;
  public filterId: any;
  public filterCols: any = [];
  public counter: number = 0;
  BlogDetails: string;

  bsValue = new Date();
  bsRangeValue: Date[];
  maxDate = new Date()
  @Input() selectedList: any;
  /* Status Valued */
  public status_items: any = [{ name: 'Active', value: 'Active' }, { name: 'Inactive', value: 'Inactive' }];

  constructor(public commonService: CommonService, public showErrorService: ShowErrorService,
    private router: Router, public bsModalRef: BsModalRef, private modalService: BsModalService,
    public restrictKeyPressService: RestrictKeyPressService) {
    this.APIparameters = { page: 1, per_page: 10 };

    this.maxDate.setDate(this.maxDate.getDate() + 7);
    this.bsRangeValue = [this.bsValue, this.maxDate];
  }

  ngOnInit() {
    this.addTask();
    // this.filteredArr[0].method = 'exclude';
    // this.tableConfig = this.tableSetupData;
    // this.configs = this.tableSetupData;

    this.type = this.tableSetupData.type;
    this.params = this.tableSetupData.params;
    this.conditions = this.tableSetupData.conditions;

    this.tableConfig.cols = this.tableSetupData.cols;
    this.tableConfig.actions = this.tableSetupData.actions;
    this.tableConfig.cols.forEach(element => {
      if (element.drag) {
        this.colSettingCols.push(element)
      }
    });

    /*************************************************************
    @Purpose : Change statusList according to role Permission
    /*************************************************************/
    //if (this.accessPermission.statusUpdate && this.accessPermission.delete) {
    this.statusList = ['Active', 'Inactive'];
    if (this.conditions.showApplyDelete) {
      this.statusList.push('Delete');
    }
    // }
    // else if (this.accessPermission.statusUpdate || (this.accessPermission.statusUpdate && !this.accessPermission.delete)) {
    //   this.statusList = ['Active', 'Inactive'];
    //  } 
    //else if (!this.accessPermission.statusUpdate && this.accessPermission.delete) {
    //  this.statusList = ['Delete'];
    // } 
    //else {
    // this.conditions.showApplyStatus = false;
    const index = this.tableConfig.cols.findIndex((o) => o.type === 'multipleSelection');
    // if (this.tableConfig.cols[index]) { this.tableConfig.cols[index].isVisible = true; }
    // }
    /*************************************************************/
    // this.callApiToGetData();

  }
  addTask() {
    this.filteredArr.push({
      condition: this.condition
    })
  }

  ngAfterViewInit() {
    this.callApiToGetData();
    /* options for datatable */
    this.dtOptions = {
      destroy: true,
      retrieve: true,
      searching: false,
      sorting: false,
      ordering: false,
      autoWidth: false,
      paging: false,
      info: false,
      responsive: {
        details: {
          renderer: Responsive.renderer.listHiddenNodes(),
        },
      },
      columnDefs: [
        { responsivePriority: 1, targets: 0 },
        { responsivePriority: 1, targets: -1 },
        { responsivePriority: 1, targets: -2 },
      ],
    };
  }

  ngOnDestroy() {
    /* Do not forget to unsubscribe the event */
    this.dtTrigger.unsubscribe();
  }

  /*************************************************************
    @Purpose : Api for List
  /*************************************************************/
  public getMethodName: any;
  public httpMethodName: any;
  public ImportEXCEL: any;
  public EXCEL: any;
  public DeleteUser: any;
  public ChangeStatus: any;
  public getFilterlist: any;
  public saveFilter: any;
  public getFilter: any;
  public externalData: any;

  async callApiToGetData() {
    if (this.type) {
      if (this.type === 'userlist') {
        this.getMethodName = this.callAPIConstants.UserList;
        this.httpMethodName = 'post';
        this.ImportEXCEL = this.callAPIConstants.ImportUsers;
        this.DeleteUser = this.callAPIConstants.UserDeleteUsers;
        this.ChangeStatus = this.callAPIConstants.UserChangeStatus;
        this.saveFilter = this.callAPIConstants.saveFilter;
      } else if (this.type === 'groupsList') {
        this.getMethodName = this.callAPIConstants.GetGroupsList;
        this.httpMethodName = 'post';
        this.DeleteUser = this.callAPIConstants.DeleteUserGroup;
        this.ChangeStatus = this.callAPIConstants.ChangeGroupStatus;
      } else if (this.type === 'assignCoursesList') {
        this.getMethodName = this.callAPIConstants.GetAssignCourses;
        this.httpMethodName = 'post';
      } else if (this.type === 'roleList') {
        this.getMethodName = this.callAPIConstants.RolesList;
        this.httpMethodName = 'post';
        this.ChangeStatus = this.callAPIConstants.RoleChangeStatus;
        this.getFilterlist = this.callAPIConstants.RoleGetColumnData;
        this.DeleteUser = this.callAPIConstants.DeleteRole;
        this.APIparameters.columnKey = this.tableSetupData.saveColumnKey;
      } else if (this.type === 'teamMemeberList') {
        this.getMethodName = this.callAPIConstants.TeamMembers;
        this.httpMethodName = 'post';
        this.ChangeStatus = this.callAPIConstants.TeamChangeStatus;
        this.DeleteUser = this.callAPIConstants.GetTeamMember;
      } else if (this.type === 'categorieslist') {
        this.getMethodName = this.callAPIConstants.QuestionCategories;
        this.APIparameters.training_course_id = this.tableSetupData.parentId;
        this.httpMethodName = 'post';
        this.ImportEXCEL = this.callAPIConstants.ImportQuestionBank;
        this.ChangeStatus = this.callAPIConstants.ChangeCategoryStatus;
        this.DeleteUser = this.callAPIConstants.GetCategoryById;
      } else if (this.type === 'photoHotspotResponsesList') {
        this.getMethodName = this.callAPIConstants.GetPocResponses;
        this.APIparameters.submodule_id = this.tableSetupData.parentId;
        this.httpMethodName = 'post';
        this.ChangeStatus = this.callAPIConstants.ChangeCategoryStatus;
        this.DeleteUser = this.callAPIConstants.GetCategoryById;
      } else if (this.type === 'courseFeedbackResponseList') {
        this.getMethodName = this.callAPIConstants.GetViewResponseCourseFeedbackList;
        this.APIparameters.submodule_id = this.tableSetupData.parentId;
        this.httpMethodName = 'post';
      } else if (this.type === 'jobsReponsesList') {
        this.getMethodName = this.callAPIConstants.GetViewResponseJobList;
        this.APIparameters.submodule_id = this.tableSetupData.parentId;
        this.httpMethodName = 'post';
      } else if (this.type === 'quizReponsesList') {
        this.getMethodName = this.callAPIConstants.GetViewResponseQuizList;
        this.APIparameters.submodule_id = this.tableSetupData.parentId;
        this.httpMethodName = 'post';
      }
      else if (this.type === 'manageResourcesList') {
        this.getMethodName = this.callAPIConstants.GetResourceList;
        this.httpMethodName = 'post';
        this.DeleteUser = this.callAPIConstants.DeleteResource;
        this.APIparameters.columnKey = this.tableSetupData.saveColumnKey;
        this.ChangeStatus = this.callAPIConstants.ChangeResourceStatus;

      } else if (this.type === 'assignProductsList' || this.type === 'productsList') {
        this.getMethodName = this.callAPIConstants.GetProductsList;
        this.httpMethodName = 'post';
      } else if (this.type === 'assignNewsList' || this.type === 'newsList') {
        this.getMethodName = this.callAPIConstants.GetNewsList;
        this.httpMethodName = 'post';
      }
      else if (this.type === 'timezoneListing') {
        this.getMethodName = this.callAPIConstants.timezoneListing;
        this.httpMethodName = 'post';
        this.ChangeStatus = this.callAPIConstants.changeTimezoneStatus;
        this.APIparameters.columnKey = this.tableSetupData.saveColumnKey;
        this.DeleteUser = this.callAPIConstants.deleteTimezones;

      } else if (this.type == 'faqsListing') {
        this.getMethodName = this.callAPIConstants.FaqsListing;
        this.httpMethodName = 'post';
        this.ChangeStatus = this.callAPIConstants.FaqsStatusChange;
        this.APIparameters.columnKey = this.tableSetupData.saveColumnKey;
        this.DeleteUser = this.callAPIConstants.DeleteFaqs;
      } else if (this.type == 'faqsCategoryListing') {
        this.APIparameters.columnKey = this.tableSetupData.saveColumnKey;
        this.getMethodName = this.callAPIConstants.FaqsCategoryListing;
        this.httpMethodName = 'post';
        this.ChangeStatus = this.callAPIConstants.FaqsCategoryStatus;
        this.DeleteUser = this.callAPIConstants.DeleteFaqsCategory;
      }
      else if (this.type == 'StaticPageList') {
        this.APIparameters.columnKey = this.tableSetupData.saveColumnKey;
        this.getMethodName = this.callAPIConstants.Listing;
        this.httpMethodName = 'post';
        this.ChangeStatus = this.callAPIConstants.ChangeStatus;
        this.DeleteUser = this.callAPIConstants.DeleteBlog;
        this.BlogDetails = this.callAPIConstants.GetBlogDetails
      } else if (this.type == 'blogCategoryList') {
        this.ChangeStatus = this.callAPIConstants.BlogCategoryStatusChange
        this.httpMethodName = 'post';
        this.DeleteUser = this.callAPIConstants.DeleteBlogCategory
        this.APIparameters.columnKey = this.tableSetupData.saveColumnKey;
        this.getMethodName = this.callAPIConstants.BlogCategoryListing;
      }
    }

    if (this.getMethodName) {
      await this.commonService.callApi(this.getMethodName, this.APIparameters, this.httpMethodName, false, false, false).then((success) => {
        if (!success.errors) {
          this.showSkeletonLoader1 = false;
          this.tableData.data = success.data;
          if (this.tableData.data && (this.type == "roleList" || this.type == "teamMemeberList" || this.type == "groupsList" || this.type == "userlist" || this.type == "categorieslist" || this.type == 'assignCoursesList' || this.type == "manageResourcesList" || this.type == 'newsList' || this.type == 'productsList')) {
            this.tableData.data.forEach(e => {
              if (e.status === 'Inactive') {
                e.status = false;
              } else if (e.status === 'Active') {
                e.status = true;
              }
            })
          }
          if (this.type === 'assignCoursesList' || this.type === 'assignProductsList' || this.type === 'assignNewsList') {
            this.selectedUserList = [];
            this.tableData.data.forEach(item => {
              if (this.APIparameters.user_group_id && this.APIparameters.user_group_id !== null) {
                this.isCheckable = false;
                this.selectedList = [];
                item.checkbox = true;
                item.disabled = true;
                this.selectMultipleUser(item);
              } else {
                this.isCheckable = true;
                this.selectedList.forEach(e => {
                  if (item.id == e) {
                    item.checkbox = true;
                    this.selectMultipleUser(item);
                  }
                });
              }
            });
          }
          this.total = success.meta && success.meta.total ? success.meta.total : this.tableData.data.length;
        } else {
          this.showSkeletonLoader1 = false;
          this.showErrorService.popToast('error', success.errors.message);
        }
      });
    }
  }

  getLatestData(success) {
    // this.tableConfig = this.tableSetupData;
    // this.configs = this.tableSetupData;
    // this.cols = [];
    // this.filterCols = [];
    // this.tableConfig.cols.forEach(element => {
    //   if (element.drag) {
    //     this.cols.push(element)
    //   }
    //   if (element.filter) {
    //     this.filterCols.push(element)
    //   }
    // });

    // this.tableConfig.cols.forEach(async (element) => {
    //   if (success.data.columnSettings && this.type !== 'adminUserlist1') {
    //     success.data.columnSettings.forEach((dataApi) => {
    //       if (element.colFieldname === dataApi.key) {
    //         element.isVisible = dataApi.status;
    //       }
    //     });
    //   }
    //   else {
    //     if (this.columns && this.columns.length) {
    //       this.columns.forEach((dataApi) => {
    //         if (element.colFieldname === dataApi.key) {
    //           element.isVisible = dataApi.status;
    //         }
    //       });
    //     }

    //   }
    // });
    //if (!this.accessPermission.statusUpdate) {
    // const index = this.tableConfig.cols.findIndex((o) => o.type === 'switch');
    // if (this.tableConfig.cols[index]) {
    //   this.tableConfig.cols[index].isVisible = false;
    //   this.tableConfig.cols[index].filter = false;
    //   if (this.tableConfig.cols[index].columnVisibility) { this.tableConfig.cols[index].columnVisibility = false; }
    // }
    //}
    // this.tableData.data = success.data.listing;
    this.rerenderDataTable();

  }
  // *************************************************************//

  /********************************************************************************
  @Purpose :  Api for downloading CSV/Excel/pdf file of datatable( with and without filter )
  /********************************************************************************/
  public count: any;
  public errorMsg = "";
  selectColumns(tableConfigCols) {
    this.count = tableConfigCols.some(ele => {
      if (ele.isSelected) {
        return true;
      }
    })
    if (!this.count) {
      delete this.options.templateName;
    }
  }
  toggleDownload() {
    this.toggleDownloadButton = !this.toggleDownloadButton;
    this.downloadColumnsArr = [];
    this.tableConfig.cols.forEach(ele => {
      ele.isSelected = false;
    })
    delete this.options.templateName;
    this.count = false;
    if (this.toggleDownloadButton) {
      this.CallAPIgetDownloadTemplates();
    }
  }
  saveDownloadTemplate(tableConfigCols) {
    this.submitted = true;
    this.downloadColumnsArr = [];
    tableConfigCols.forEach((element) => {
      if (element.columnVisibility) {
        this.downloadColumnsArr.push({ key: element.colFieldname, status: element.isSelected });
      }
    });
    const data: any = { columns: this.downloadColumnsArr, color: this.color, description: this.options.templateName };
    if (this.tableSetupData.saveColumnKey) {
      data.key = this.tableSetupData.saveColumnKey;
    }
    if (this.options.id) {
      data.templateId = this.options.id;
    }
    if (this.options.templateName) {
      this.commonService.callApi(this.downloadColumns, data, 'post').then((success) => {
        if (success.status === 1) {
          this.CallAPIgetDownloadTemplates();
          this.toggleDownload();
          this.showErrorService.popToast('success', 'template saved successfully');
          this.callApiToGetData();
        }
        else {
          this.showErrorService.popToast('error', success.message);
        }
      });
    }
    else {
      this.showErrorService.popToast('error', 'please enter template Name');
    }
  }

  exportTemplate() {
    let templateData: any = { exportFields: [] };
    templateData.isExport = 1;
    this.tableConfig.cols.forEach((element) => {
      if (element.columnVisibility && element.isVisible) {
        templateData.exportFields.push(element.colFieldname);
      }
    });
    let apiName = this.getMethodName;
    let methodType = 'post';
    if (this.type === 'categorieslist') {
      apiName = this.callAPIConstants.ExportQuestionBank + this.APIparameters.training_course_id;
      methodType = 'get';
    }
    this.commonService.callApi(apiName, templateData, methodType, false, false).then(success => {
      if (!success.errors) {
        const blob = new Blob([success.text], { type: 'text/csv' });
        let fileName;
        if (this.type === 'categorieslist') {
          fileName = 'Question-bank-'
        } else if (this.type === 'userlist') {
          fileName = 'Users-';
        }
        saveAs(blob, fileName + new Date().getTime() + '.csv');
        this.commonService.showSkeletonLoader = false;
      } else {
        this.showErrorService.popToast('error', 'Error downloading the file.');
      }
    });
  }

  importTemplate(event) {
    const file = event.target && event.target.files && event.target.files[0];
    const fd = new FormData();
    fd.append('file', file);
    if (this.type === 'categorieslist') {
      fd.append('training_course_id', this.tableSetupData.parentId);
    }
    this.commonService.callApi(this.ImportEXCEL, fd, 'post', false, true, false).then((success) => {
      if (!success.errors) {
        this.showErrorService.popToast("success", success.extra_meta.message);
        this.callApiToGetData();
      } else {
        this.commonService.showSkeletonLoader = false;
        this.showErrorService.popToast("error", success.errors.message);
      }
    });
  }

  editTemplate(i) {
    this.options = i;
    this.options.templateName = i.description;
    this.color = i.color;
    var hash = this.colors.find(a => a.name == i.color).hex;
    document.getElementById("downloadDropdownColor").style.backgroundColor = hash;
    this.count = true;
    this.tableConfig.cols.forEach(ele => {
      i.columns.forEach(ele1 => {
        if (ele1.status && ele1.key == ele.colFieldname) {
          ele.isSelected = true;
        }
        // else {
        //   ele.isSelected = false;
        // }
      })
    })
  }
  methodChange(i, key) {
    i.method = key;
  }

  removeTask(index) {
    this.filteredArr.splice(index, 1);
  }

  conditionChange(key) {
    this.condition = key;
  }

  applyLatestFilter(form?, item?) {
    this.submitted = true;
    if (form) {
      // this.filteredArr[0].push({
      //   condition:this.condition
      // })
      this.APIparameters = {
        page: 1,
        per_page: 10,
        columnKey: this.APIparameters.columnKey,
        filter: this.filteredArr,
        //condition: this.condition
      }
      // this.callApiToGetData();
    } else {
      this.APIparameters = {
        page: 1,
        per_page: 10,
        columnKey: this.APIparameters.columnKey,
        filter: item.filter,
        condition: item.condition
      }
      this.callApiToGetData();
    }
    this.openFilter();

  }

  /*************************************************************
    @Purpose : select users and change status
  /*************************************************************/
  public statusFormData = { status: '' };
  public statusList: any[];

  selectMultipleUser(data) {
    if (data.checkbox) {
      if (this.selectedUserList.length) {
        const x = this.selectedUserList.findIndex((o) => o === data.id);
        if (x === -1) {
          this.selectedUserList.push(data.id);
        }
      } else {
        this.selectedUserList.push(data.id);
      }
    } else {
      this.tableConfig.cols.forEach((element) => {
        element.checkbox = false;
      });
      const x = this.selectedUserList.findIndex((o) => o === data.id);
      if (x > -1) {
        this.selectedUserList.splice(x, 1);
      }
    }
  }

  submitStatusForm(statusForm, statusFormData) {
    this.submitted = true;
    if (this.selectedUserList.length) {
      /* DELETE */
      if (statusFormData.status === 'Delete') {
        this.showErrorService.confirmpopToast('warning', 'Are you sure, you want to delete??').then((result) => {
          if (result.value === true) {
            const data = { [this.params.deleteParams]: this.selectedUserList };
            this.CallAPIchangeStatusORDelete(this.DeleteUser, data, 'post');
          } else {
            const index = this.tableConfig.cols.findIndex((o) => o.type === 'multipleSelection');
            this.tableConfig.cols[index].checkbox = false;
            this.deselectMultipleUser();
          }
        });
      } else if (statusFormData.status === 'Inactive' || statusFormData.status === 'Active') {
        let data;
        statusFormData.status === 'Inactive' ?
          data = { [this.params.statusParams]: this.selectedUserList, status: 'Inactive' } :
          data = { [this.params.statusParams]: this.selectedUserList, status: 'Active' };
        this.CallAPIchangeStatusORDelete(this.ChangeStatus, data, 'post');
        const index = this.tableConfig.cols.findIndex((o) => o.type === 'multipleSelection');
        this.tableConfig.cols[index].checkbox = false;
      }
    } else {
      this.showErrorService.popToast('error', 'Select Users To Modify');
    }
  }
  // *************************************************************//

  /***************************************************************
    @Purpose : Pagination
  /***************************************************************/
  public itemsPerPageList = [5, 10, 25, 100];
  itemsPerPage = 10;

  pagination(e) {
    this.APIparameters.page = e.page;
    this.itemsPerPage = e.itemsPerPage;
    this.callApiToGetData();
    setTimeout(() => {
      if (this.selectedUserList.length) {
        this.selectedUserList.forEach((element) => {
          const x = this.tableData.data.findIndex((o) => element === o.id);
          if (x > -1) {
            this.tableData.data[x].checkbox = true;
          }
        });
      }
    }, 500);
  }

  selectItemPerPage(e) {
    this.customPagination._page = 1;
    this.APIparameters.per_page = e;
    this.APIparameters.page = 1;
    this.callApiToGetData();
  }

  /***************************************************************/

  /***************************************************************
    @Purpose : change visibility of columns
  /***************************************************************/

  public columnVisibleArr: any[] = [];

  tooglecolumnVisibilityFilter() {
    this.columnVisibilityOptions = !this.columnVisibilityOptions;
    this.filterColumnOption = false;
  }

  importCSVandExcel(key) {
    if (this.type == 'countriesListing') {
      if (key == 'csv') {
        this.commonService.router.navigate(['', 'master', this.type, 'csv'])
      }
      else {
        this.commonService.router.navigate(['', 'master', this.type, 'excel'])
      }
    }
  }

  changeCheckBoxValue() {
    let count = 0;
    this.tableConfig.cols.forEach((element) => {
      if (element.columnVisibility === true && element.isVisible === true) { count++; }
    });
    if (count === 1) {
      const index = this.tableConfig.cols.findIndex((o) => o.isVisible === true && o.columnVisibility === true);
      if (index > -1 && this.tableConfig.cols[index]) {
        this.tableConfig.cols[index].disable = true;
      }
    } else {
      this.tableConfig.cols.forEach((element) => {
        element.disable = false;
      });
    }
  }

  selectAllColumnVisible() {
    this.tableConfig.cols.forEach((element) => {
      element.isVisible = true;
    });
  }

  resetColumnVisible() {
    this.callApiToGetData();
  }
  /***************************************************************/

  /*************************************************************
  @Purpose    : Api for change Status and Delete
  @parameters : callAPi ==> name of Api to be called
                data ==> data to be send
  /*************************************************************/
  CallAPIchangeStatusORDelete(callAPI, data, methodName) {
    this.commonService.callApi(callAPI, data, methodName, false, false, false).then((success) => {
      if (!success.errors) {
        this.showErrorService.popToast('success', success.extra_meta.message);
        delete this.statusFormData.status
        setTimeout(() => {
          this.callApiToGetData();
        });
        this.selectedUserList = [];
      } else {
        this.showErrorService.popToast('error', success.errors.message);
      }
    });
  }

  /*************************************************************
    @Purpose :  Rerendering Datatable
  /*************************************************************/
  async rerenderDataTable() {
    if (this.dtElement.dtInstance) {
      this.dtElement.dtInstance.then(async (dtInstance: DataTables.Api) => {
        /* Destroy the table first */
        dtInstance.destroy();
        /* Call the dtTrigger to rerender again */
        this.dtTrigger.next();
      });
    } else { this.dtTrigger.next(); }
  }

  /*************************************************************
    @Purpose : Sorting for user listing
  /*************************************************************/

  onSorted(event) {
    this.APIparameters.sort_by = event.sortColumn.colFieldname;
    this.APIparameters.order_by = event.sortDirection;
    this.callApiToGetData();
  }

  /***************************************************************
    @Purpose :  Perform Action: VIEW, EDIT, DELETE And SWITCH
  /***************************************************************/
  performAction(k, i) {
    switch (k.id) {
      /* VIEW action */
      case 1:
        let detailPageUrl = '';
        switch (this.type) {
          case 'userlist':
            detailPageUrl = this.URLConstants.USERS_DETAIL;
            break;
          case 'categorieslist':
            detailPageUrl = this.URLConstants.EDIT_QUESTION_BANK + this.tableSetupData.parentId + '/';
            break;
          case 'jobsReponsesList':
            detailPageUrl = this.router.url + '/';
            break;
          case 'courseFeedbackResponseList':
            detailPageUrl = this.router.url + '/';
          case 'photoHotspotResponsesList':
            detailPageUrl = this.router.url + '/';
            break;
          case 'manageResourcesList':
            detailPageUrl = this.URLConstants.VIEW_RESOURCE + '/';
          default:
            break;
        }
        this.router.navigate([detailPageUrl, this.type !== 'courseFeedbackResponseList' ? i.id : i.uid])
        break;
      /* EDIT action */
      case 2:
        let editPageUrl = '';
        switch (this.type) {
          case 'roleList':
            editPageUrl = this.URLConstants.EDIT_ROLE;
            break;
          case 'teamMemeberList':
            editPageUrl = this.URLConstants.EDIT_TEAM_MEMBER;
            break;
          case 'userlist':
            editPageUrl = this.URLConstants.EDIT_USER;
            break;
          case 'groupsList':
            editPageUrl = this.URLConstants.EDIT_GROUP;
            break;
          case 'manageResourcesList':
            editPageUrl = this.URLConstants.EDIT_RESOURCE;
            break;
          default:
            break;
        }
        this.router.navigate([editPageUrl, i.id]);
        break;
      /* DELETE action */
      case 3:
        this.showErrorService.confirmpopToast('warning', 'Are you sure, you want to delete??').then((result) => {
          if (result.value === true) {
            const dataObj = {
              [this.params.deleteParams]: [i.id],
            };
            let methodType = 'post';
            if (this.type === 'teamMemeberList' || this.type === 'categorieslist') {
              this.DeleteUser = this.DeleteUser + i.id;
              methodType = 'delete';
            }
            this.CallAPIchangeStatusORDelete(this.DeleteUser, dataObj, methodType);
          }
        });
        break;
      /* OTHER action */
      case 4:
        switch (this.type) {
          case 'categorieslist':
            const initialState = { courseId: this.tableSetupData.parentId, category: i };
            this.modalService.show(AddEditCategoryComponent, { class: 'modal-dialog-centered category-modal', initialState });
            this.modalService.onHidden.subscribe(() => {
              this.callApiToGetData();
            });
            break;
          case 'quizReponsesList':
            this.commonService.callApi(this.callAPIConstants.MarkAsPassed + i.id, {}, 'get', false, false, false).then((success) => {
              if (!success.errors) {
                this.showErrorService.popToast('success', success.extra_meta.message);
              } else {
                this.showErrorService.popToast('error', success.errors.message);
              }
            });
            break;
        }
      case 5:

      default:
        break;
    }
  }
  /* SWITCH action */
  changeStatus(d) {
    let dataObj: any = {
      [this.params.statusParams]: [d.id],
      status: d.status ? 'Inactive' : 'Active'
    };
    this.CallAPIchangeStatusORDelete(this.ChangeStatus, dataObj, 'post');
  }
  /***************************************************************
    @Purpose :  Drag & Drop columns
  /***************************************************************/
  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.colSettingCols, event.previousIndex, event.currentIndex);
  }

  /***************************************************************
    @Purpose :  SELECT ALL USERS IN TABLE
  /***************************************************************/
  selectAllUser(i) {
    if (i.checkbox === true) {
      this.tableData.data.forEach((element) => {
        element.checkbox = true;
        if (this.selectedUserList.length) {
          const x = this.selectedUserList.findIndex((o) => o === element.id);
          if (x === -1) {
            this.selectedUserList.push(element.id);
          }
        } else {
          this.selectedUserList.push(element.id);
        }
      });
    } else {
      this.deselectMultipleUser();
    }
  }

  /***************************************************************
    @Purpose :  Common Function
  /***************************************************************/
  deselectMultipleUser() {
    this.tableConfig.cols.forEach((element) => {
      element.checkbox = false;
    });
    this.tableData.data.forEach((element) => {
      element.checkbox = false;
      const x = this.selectedUserList.findIndex((o) => o === element.id);
      if (x > -1) {
        this.selectedUserList.splice(x, 1);
      }
    });
  }

  /***************************************************************
    @Purpose :  filter
  /***************************************************************/
  statusFilterlist: any[] = [];
  public filters = {};
  public disableApplyFilter = true;
  public disableResetFilter = true;

  toggleFilterColumn() {
    this.filterColumnOption = !this.filterColumnOption;
    this.columnVisibilityOptions = false;
  }

  getSatutsFilterlistData(colName) {
    if (colName === 'status' && this.type !== 'jobsReponsesList') {
      this.statusFilterlist = ['Active', 'Inactive'];
    } else {
      this.statusFilterlist = ['Approved', 'Pending', 'Rejected'];
    }
  }

  applyFilter() {
    this.filters = {};
    this.tableConfig.cols.forEach(e => {
      if (e.filter && e.value) {
        if (e.isDate || e.isDateTime) {
          this.filters[e.colFieldname] = new DatePipe('en-US').transform(e.value, 'yyyy-MM-dd');
        } else {
          this.filters[e.colFieldname] = e.value;
        }
      }
    });
    if (Object.keys(this.filters).length !== 0) {
      this.disableResetFilter = false;
      this.APIparameters.filters = this.filters;
      this.callApiToGetData();
    } else {
      this.showErrorService.popToast('error', 'Please fill at least one field to filter')
    }
  }

  resetFilter() {
    this.disableResetFilter = true;
    this.filters = {};
    this.tableConfig.cols.forEach((element) => {
      element.value = '';
    });
    this.APIparameters.filters = {};
    this.callApiToGetData();
  }
  /***************************************************************/

  CallAPIgetDownloadTemplates() {
    if (this.getDowloadTemplates) {
      this.commonService.callApi(`${this.getDowloadTemplates}/${this.tableSetupData.saveColumnKey}`, '', 'get', false, false).then((success) => {
        if (success.status === 1) {
          this.downloadTemplatesArr = success.data;
        }
      });
    }
  }

  dateCreated(fieldName, $event) {
    // if ($event != undefined && $event.length) {
    //     let startDate = ('0' + ($event[0].getMonth() + 1)).slice(-2) + '/' + ('0' + $event[0].getDate()).slice(-2) + '/' + $event[0].getFullYear();
    //     let endDate = ('0' + ($event[1].getMonth() + 1)).slice(-2) + '/' + ('0' + $event[1].getDate()).slice(-2) + '/' + $event[1].getFullYear();
    //     let dateFilterData = { 'startDate': startDate, 'endDate': endDate }
    //     const check_index = this.filter.findIndex((o) => Object.keys(o).includes(fieldName));
    //     if (check_index > -1) { this.filter[check_index][fieldName] = dateFilterData; } else {
    //       this.filter.push({ [fieldName]: dateFilterData });
    //     }
    //     this.disableApplyFilter = false;
    //     this.disableResetFilter = false;
    // }
  }
  /***************************************************************
    @Purpose :  To get search list
  /***************************************************************/
  searchData(event) {
    delete this.APIparameters.sort_by;
    delete this.APIparameters.order_by;

    this.APIparameters.page = 1;
    if (event.target && event.target.value) {
      this.APIparameters.search_key = event.target.value;
    } else {
      delete this.APIparameters.search_key;
    }
    this.callApiToGetData();
  }

  /***************************************************************
  @Purpose : To Get Dynamic searchValues.
  /***************************************************************/
  searchValue(data) {
    alert(data);
    this.APIparameters.search_key = data;
    this.callApiToGetData();
  }

  /* For performing selected action on the list */
  actionSelected(action) {
    /* DELETE */
    if (action === 'Delete') {
      this.showErrorService.confirmpopToast('warning', 'Are you sure, you want to delete??').then((result) => {
        if (result.value === true) {
          const data = {
            userIds: this.selectedUserList
          };
          this.CallAPIchangeStatusORDelete(this.DeleteUser, data, 'delete');
        } else {
          this.deselectMultipleUser();
        }
      });
    }
    /* STATUS */
    if (action === 'Inactive' || action === 'Active') {
      let data;
      action === 'Inactive' ?
        data = { userIds: this.selectedUserList, status: false } :
        data = { userIds: this.selectedUserList, status: true };
      this.CallAPIchangeStatusORDelete(this.ChangeStatus, data, 'post');
      this.deselectMultipleUser();
    }
  }

  /* To get the selected dateType info */
  dateSelected(e, index) {
    if (e.name !== 'custom') {
      var date = new Date();
      let presentDate = (e.name === 'Previous Month' || e.name === 'Previous Quarter' || e.name === 'Previous Year') ? 'month/01/year' : date.toLocaleDateString();
      let value = {
        calendarSpecificCount: e.value,
        calendarSpecificType: e.type,
        presentDate: presentDate
      }
      this.filteredArr[index]['value'] = value;
    }
  }

  /* when getting startDate and endDate */
  rangeSelected(e, index) {
    if (e) {
      let value = {
        startDate: e[0].toLocaleDateString(),
        endDate: e[1].toLocaleDateString()
      }
      this.filteredArr[index]['value'] = value;
    }
  }

  typeArrChanges(item) {
    delete (item.selectedDate);
    delete (item.value);
  }

  removeSearch() {
    delete this.searchText;
    delete this.APIparameters.search_key;
    this.callApiToGetData();
  }
}
