import { Component, Input, OnChanges, OnInit } from '@angular/core';

@Component({
  selector: 'preview-course-feedback',
  templateUrl: './course-feedback.component.html',
  styles: [
  ]
})
export class CourseFeedbackComponent implements OnInit, OnChanges {
  @Input() details: any;
  max = 5;
  rate = 0;
  isReadonly = false;
  submoduleName = {};
  questionsArr = [];

  confirmSelection(event: KeyboardEvent) {
    if (event.keyCode === 13 || event.key === 'Enter') {
      this.isReadonly = true;
    }
  }

  constructor() { }

  ngOnChanges() {
    this.questionsArr = this.details.questionsArr;
    this.submoduleName = this.details.submodule_name;
  }

  ngOnInit(): void {
    this.questionsArr = this.details.questionsArr;
    this.submoduleName = this.details.submodule_name;
  }

  addHeading(value) {
    if (!(new RegExp('^(?=.*[#])').test(value))) {
      return `### ${value}`
    } else {
      return value
    }
  }

}
