import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
/************ constants  ************/
import { callAPIConstants } from 'src/app/common/constants/callAPI-constants';
import { URLConstants } from 'src/app/common/constants/routerLink-constants';
/************ service  ************/
import { CommonService } from '../../common/service/common.service';
import { ShowErrorService } from '../../common/service/show-error.service';
import { RestrictKeyPressService } from '../../common/service/restrict-key-press.service';

@Component({
  selector: 'app-confirm-email',
  templateUrl: './confirm-email.component.html'
})

export class ConfirmEmailComponent implements OnInit {
  callAPIConstants = callAPIConstants;
  URLConstants = URLConstants;

  public email: any = {};
  public submitted: boolean = false;
  public showTimer: boolean = true;

  constructor(private router: Router, private commonService: CommonService, public showErrorService: ShowErrorService, public restrictKeyPressService: RestrictKeyPressService) { }

  ngOnInit(): void {
  }

  /*************************************************************
  @PURPOSE      : Verify OTP
  @PARAMETERS   : form, code
  @RETURN       : NA
  /*************************************************************/
  VerifyCode(form, email) {
    this.submitted = true;
    if (form.valid) {
      let data = {
        email: this.commonService.decrypt(window.sessionStorage.getItem('email')),
        token: email.code
      }
      this.commonService.callApi(this.callAPIConstants.VerifyOTP, data, 'post', true, false).then((success) => {
        if (success.data && success.data.token_verified) {
          window.sessionStorage.setItem('token', this.commonService.encrypt(email.code));
          this.showErrorService.popToast('success', 'OTP Verified');
          this.router.navigate([URLConstants.RESET_PASS])
        } else if (success.data && !success.data.token_verified) {
          this.showErrorService.popToast('error', success.extra_meta.message);
        } else {
          this.showErrorService.popToast('error', success.errors.message);
        }
      })
    }
  }

  /*************************************************************
  @PURPOSE      : Resend OTP
  @PARAMETERS   : form, email
  @RETURN       : NA
  /*************************************************************/
  resendVerificationCode() {
    let data = {
      email: this.commonService.decrypt(window.sessionStorage.getItem('email')),
    }
    this.commonService.callApi(this.callAPIConstants.ForgotPassword, data, 'post', true, false).then((success) => {
      if (!success.errors) {
        this.showErrorService.popToast('success', 'Please check your mail, we have send you a new 6-digit code');
        this.showTimer = true;
      } else {
        this.showErrorService.popToast('error', success.message);
      }
    })
  }

  /*************************************************************
  @PURPOSE      : To show resend code button
  /*************************************************************/
  handleEvent(e) {
    if (e.action === 'done') {
      this.showTimer = false;
    }
  }
  
}
