<section class="login-wrapper" style="background-image:url(assets/images/login-bg.jpg);">
    <div class="login-body">
        <div class="login-container">
            <div class="logo-box">
                <a href="javascript:;">
                    <img src="assets/images/logo.svg" alt="Logo">
                </a>
            </div>
            <div class="login-content">
                <h1>Password Reset</h1>
                <p>We will help you reset your password</p>
            </div>
            <div class="login-form">
                <form (ngSubmit)="onForgotPassword(forgetPassword, user)" #forgetPassword="ngForm" autocomplete="off">
                    <div class="form-group">
                        <label for="email">Email</label>
                        <input type="text" name="email" id="email" placeholder="Enter Email Address" pattern="^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$" class="form-control" [ngClass]="((submitted || email.touched) && email.invalid)?'has-danger':''" [(ngModel)]="user.email" required #email="ngModel">
                        <div *ngIf="(submitted || email.touched) && email.invalid" class="has-error">
                            {{showErrorService.showError('email', email.errors)}}
                        </div>
                    </div>
                    <div class="login-btn">
                        <button class="btn btn-primary btn-block" type="submit">Reset Password</button>
                    </div>
                    <div class="login-bottom">
                        <p>Remembered your password?</p>
                        <a href="javascript:;" class="btn btn-outline btn-primary btn-block" [routerLink]="[URLConstants.LOGIN]">Back to Sign In</a>
                    </div>
                </form>
            </div>
        </div>
    </div>
</section>