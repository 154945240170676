export const URLConstants = {
    /***** Unauthorized Pages *****/
    LOGIN: '/login',
    FORGOT_PASS: '/password-reset',
    VERIFY_CODE: '/verify-code',
    RESET_PASS: '/reset-password',

    /***** Authorized Pages *****/
    MY_PROFILE: '/my-profile',
    MASTER: '/master',
    DASHBOARD: '/dashboard',
    NOTIFICATIONS: '/notifications',


    /***** Courses Module *****/
    COURSES_LIST: '/courses',
    ADD_COURSE: '/courses/add',
    REQUEST_COURSE: '/courses/request-a-course',
    VIEW_COURSE: '/courses/view/',
    EDIT_COURSE: '/courses/edit/',
    ADD_MODULE: '/courses/add-module/',
    EDIT_MODULE: '/courses/edit-module/',
    VIEW_ASSIGNED_USERS: '/courses/assigned-users/',
    QUESTION_BANK: '/courses/question-bank/',
    EDIT_QUESTION_BANK: '/courses/question-bank/view/',

    /***** Courses Sub Module *****/
    SUBMODULE: '/courses/sub-module/',
    TITLE_SLIDE: '/courses/sub-module/title-slide',
    TOUR_360: '/courses/sub-module/360-tour',
    UPLOAD_VIDEO: '/courses/sub-module/upload-video',
    IMAGE_GALLERY: '/courses/sub-module/image-gallery',
    PRODUCT_LISTING: '/courses/sub-module/product-listing',
    HAPPY_UNHAPPY_FACT: '/courses/sub-module/happy-unhappy-fact',
    COURSE_FEEDBACK: '/courses/sub-module/course-feedback',
    VIDEO_GUIDE: '/courses/sub-module/video-guide',
    IMAGE_HOTSPOT: '/courses/sub-module/image-hotspot',
    CONFIRMATION: '/courses/sub-module/confirmation',
    PHOTO_HOTSPOT_UPLOADER: '/courses/sub-module/photo-hotspot-uploader',
    QUIZ: '/courses/sub-module/quiz',

    COURSE_FEEDBACK_RESPONSES: '/courses/view-response/course-feedback/',
    JOBS_RESPONSES: '/courses/view-response/jobs/',
    HOTSPOT_RESPONSES: '/courses/view-response/photo-hotspot-uploader/',
    QUIZ_RESPONSES: '/courses/view-response/quiz/',

    /***** Product Module *****/
    PRODUCT: '/product',
    ADD_PRODUCT: '/product/add',
    EDIT_PRODUCT: '/product/edit/',
    VIEW_PRODUCT: '/product/view/',

    /***** Resources Module *****/
    RESOURCES: '/resource',
    ADD_RESOURCE: '/resource/add',
    EDIT_RESOURCE: '/resource/edit/',
    VIEW_RESOURCE: '/resource/view/',

    /***** NEWS Module *****/
    NEWS: 'news',
    ADD_NEWS: '/news/add',
    EDIT_NEWS: '/news/edit/',
    VIEW_NEWS: '/news/view/',

    /***** Jobs Module *****/
    JOBS: '/jobs',

    /***** My Account Module *****/
    MY_ACCOUNT: '/my-account',
    CHANGE_PASS: '/my-account/change-password',
    NOTIFICATION_SETTING: '/my-account/notification-settings',

    /***** Users Module *****/
    USERS: '/users',
    USERS_DETAIL: '/users/view-details/',
    ASSIGNED_USERS: '/users/assigned-users',
    ADD_USER: '/users/add',
    EDIT_USER: '/users/edit/',
    /***** Group list *****/
    GROUPS: '/groups',
    GROUP_DETAIL: '/groups/view/',
    CREATE_GROUP: '/groups/add',
    EDIT_GROUP: '/groups/edit/',

    /***** Manage Roles *****/
    MANAGE_ROLES: '/manage-roles',
    ADD_ROLE: '/manage-roles/add-role',
    EDIT_ROLE: '/manage-roles/edit-details/',

    /***** Manage Team *****/
    TEAM_MEMBERS_LIST: '/manage-team',
    ADD_TEAM_MEMBER: '/manage-team/add-member',
    EDIT_TEAM_MEMBER: '/manage-team/edit-member/',

    /***** Preview Pages *****/
    SLIDE1: '/slide1',
    SLIDE2: '/slide2',
    SLIDE3: '/slide3',
    SLIDE4: '/slide4',
    SLIDE5: '/slide5',
    SLIDE6: '/slide6',
    SLIDE7: '/slide7',
    SLIDE8: '/slide8',
    SLIDE9: '/slide9',
    SLIDE10: '/slide10',
    SLIDE11: '/slide11',
    SLIDE12: '/slide12',
    SLIDE13: '/slide13',
    SLIDE14: '/slide14',

    /***** Setting Pages *****/
    SETTING_GENERAL: '/setting/setting-general',
    SETTING_SOCIALMEDIA: '/setting/setting-socialmedia',
    SETTING_PAYMENTGATEWAY: '/setting/setting-paymentgateway',
    SETTING_SMTPSMS: '/setting/setting-smtpsms',
    SETTING_EMAILNOTIFICATION: '/setting/setting-emailnotification',


};
