import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

/************ environment  ************/
import { environment } from 'src/environments/environment';
/************ constants  ************/
import { callAPIConstants } from '../../common/constants/callAPI-constants';
import { URLConstants } from '../../common/constants/routerLink-constants';
/************ service  ************/
import { CommonService } from '../../common/service/common.service';
import { LocalStorageService } from '../../common/service/local-storage.service';
import { ShowErrorService } from '../../common/service/show-error.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html'
})

export class LoginComponent implements OnInit {
  callAPIConstants = callAPIConstants;
  URLConstants = URLConstants;
  public isSettingOpen = false;
  public submitted: boolean = false;
  passwordType: boolean;
  public user: any = {};
  uploadImageUrl = environment.uploadImgUrl;

  constructor(private router: Router,
    private localStorageService: LocalStorageService, private commonService: CommonService, public showErrorService: ShowErrorService) { }

  ngOnInit(): void {
    if (this.localStorageService.getToken('Id') != null && this.localStorageService.getToken('Pass') != null) {
      this.user = {
        email: this.localStorageService.getToken('Id'),
        password: this.commonService.decrypt(this.localStorageService.getToken('Pass')),
        keeplogin: true,
      };
    }
  }

  /*************************************************************
  @PURPOSE      : Verify OTP
  @PARAMETERS   : form, Obj
  @RETURN       : NA
  /*************************************************************/
  onLogin(form, user) {
    this.submitted = true;
    if (form.valid) {
      this.commonService.callApi(this.callAPIConstants.AdminLogin, user, 'post', true, false).then((success) => {
        if (!success.errors) {
          if (user.keeplogin) {
            this.localStorageService.setToken('Id', user.email);
            this.localStorageService.setToken('Pass', this.commonService.encrypt(user.password));
          } else {
            this.localStorageService.removeToken('Id');
            this.localStorageService.removeToken('Pass');
          }
          this.localStorageService.setToken('accessToken', success.extra_meta.token);
          this.localStorageService.setToken('name', success.data.name);
          this.localStorageService.setToken('email', success.data.email);
          this.localStorageService.setToken('enable_unique_id', success.data.enable_unique_id);
          this.localStorageService.setToken('enable_manager_email', success.data.enable_manager_email);
          this.localStorageService.setToken('enable_custom_contact_info', success.data.enable_custom_contact_info);
          this.localStorageService.setToken('userId', success.data.id)
          if (success.data.photo != undefined) {
            this.localStorageService.setToken('photo', this.uploadImageUrl + success.data.photo);
          }
          this.showErrorService.popToast('success', 'Logged In successfully');
          this.router.navigate([this.URLConstants.DASHBOARD]);
        } else {
          this.showErrorService.popToast('error', success.errors.message);
        }
      });
    }


  }
  /*************************************************************/

  toggleFieldTextType() {
    this.passwordType = !this.passwordType;
  }

}
