import { Component, Input, OnChanges, OnInit } from '@angular/core';

@Component({
  selector: 'preview-video-guide',
  templateUrl: './video-guide.component.html',
  styles: [
  ]
})
export class VideoGuideComponent implements OnInit, OnChanges {
  @Input() details: any;
  submoduleName: string = "";
  detailScreen: boolean = false;
  videosArray = [];
  currentIndex;
  constructor() { }
  // https://img.youtube.com/vi/gIwgSpEg6ZY/default.jpg
  ngOnChanges() {
    this.submoduleName = this.details.submodule_name;
    this.videosArray = this.details.videosArray;
  }

  ngOnInit(): void {
    this.submoduleName = this.details.submodule_name;
    this.videosArray = this.details.videosArray;
  }

  navigateToDetail(index) {
    this.detailScreen = true;
    this.currentIndex = index;
  }

  addHeading(value) {
    if (!(new RegExp('^(?=.*[#])').test(value))) {
      return `### ${value}`
    } else {
      return value
    }
  }
}
