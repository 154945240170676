<section class="login-wrapper" style="background-image:url(assets/images/login-bg.jpg);">
    <div class="login-body">
        <div class="login-container">
            <div class="logo-box">
                <a href="javascript:;">
                    <img src="assets/images/logo.svg" alt="Logo">
                </a>
            </div>
            <div class="login-content">
                <h1>Confirm Email</h1>
                <p>Check your email and enter confirmation code</p>
            </div>
            <div class="login-form">
                <form (ngSubmit)="VerifyCode(confirmEmail, email)" #confirmEmail="ngForm" autocomplete="off">
                    <div class="form-group">
                        <label for="code">Confirmation Code</label>
                        <input type="text" name="code" id="code" [(ngModel)]="email.code" required #code="ngModel" placeholder="Enter Code" class="form-control" [ngClass]="((submitted || code.touched) && code.invalid)?'has-danger':''" minlength="6" maxlength="6" (keydown)="restrictKeyPressService.AllowNumbers($event)">
                        <div *ngIf="(submitted || code.touched) && code.invalid" class="has-error">
                            {{showErrorService.showError('code', code.errors)}}
                        </div>
                    </div>
                    <div class="login-btn">
                        <button class="btn btn-primary btn-block" type="submit">Confirm Email</button>
                    </div>
                    <div class="login-bottom">
                        <p>Haven’t received your code?</p>
                        <a href="javascript:;" *ngIf="showTimer" class="btn btn-outline btn-primary btn-block disabled" >Resend Code in <countdown  #cd [config]="{ leftTime: 30, format: 'm:s' }"
                            (event)="handleEvent($event)"></countdown>s</a>
                        <a href="javascript:;" *ngIf="!showTimer" class="btn btn-outline btn-primary btn-block" (click)="resendVerificationCode()">Resend Code</a>
                    </div>
                </form>
            </div>
        </div>
    </div>
</section>
