import { Component, Input, OnChanges, OnInit } from '@angular/core';

@Component({
  selector: 'preview-jobs',
  templateUrl: './jobs.component.html',
  styles: [
  ]
})
export class JobsComponent implements OnInit, OnChanges {
  @Input() details: any;
  jobArr: any = [];
  submoduleName: string = "";
  constructor() { }

  ngOnChanges() {
    this.jobArr = this.details.jobArr;
    this.submoduleName = this.details.submodule_name;
  }

  ngOnInit(): void {
    this.jobArr = this.details.jobArr;
    this.submoduleName = this.details.submodule_name;
  }

  addHeading(value) {
    if (!(new RegExp('^(?=.*[#])').test(value))) {
      return `### ${value}`
    } else {
      return value
    }
  }

  course = [
    { id: 1, name: 'CSP & Inside/ Out Cable 1' },
    { id: 2, name: 'CSP & Inside/ Out Cable 2' },
  ];

}
