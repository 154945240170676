import { Component, Input, OnChanges, OnInit } from '@angular/core';

@Component({
  selector: 'preview-image-gallery',
  templateUrl: './image-gallery.component.html',
  styles: [
  ]
})
export class ImageGalleryComponent implements OnInit, OnChanges {
  @Input() details: any;
  slides = []
  submoduleName: string = "";
  constructor() { }

  ngOnChanges() {
    this.slides = this.details.galleries;
    this.submoduleName = this.details.submodule_name;
  }

  ngOnInit(): void {
    this.slides = this.details.galleries;
    this.submoduleName = this.details.submodule_name;
  }

  slideConfig = {
    'slidesToShow': 1,
    'slidesToScroll': 1,
    'infinite': true,
    'draggable': true,
    'speed': 500,
    'variableWidth': false,
    'dots': true,
    'arrows': false,
  };

  addHeading(value) {
    if (!(new RegExp('^(?=.*[#])').test(value))) {
      return `### ${value}`
    } else {
      return value
    }
  }

}
