import { Component, Input, OnChanges, OnInit } from '@angular/core';

@Component({
  selector: 'preview-title-slide',
  templateUrl: './title-slide.component.html',
  styles: [
  ]
})
export class TitleSlideComponent implements OnInit, OnChanges {

  @Input() details: any;
  previewData: any;

  constructor() { }

  ngOnChanges() {
    this.previewData = this.details;
  }
  ngOnInit(): void {
    this.previewData = this.details;
  }

  addhash(value) {
    if (!(new RegExp('^(?=.*[#])').test(value))) {
      return `# ${value}`
    } else {
      return value
    }
  }
  addHeading(value) {
    if (!(new RegExp('^(?=.*[#])').test(value))) {
      return `### ${value}`
    } else {
      return value
    }
  }

}
