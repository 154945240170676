import { Component, Input, OnChanges, OnInit } from '@angular/core';

@Component({
  selector: 'preview-image-hotspot',
  templateUrl: './image-hotspot.component.html',
  styles: [
  ]
})
export class ImageHotspotComponent implements OnInit, OnChanges {
  @Input() details: any;
  constructor() { }
  submoduleName: string;
  hotspotImageUrl: string;
  hotspotArray: any = [];


  ngOnChanges() {
    this.submoduleName = this.details.submodule_name;
    this.hotspotImageUrl = this.details.hotspot_image_url;
    this.hotspotArray = this.details.hotspotArray;
  }

  ngOnInit(): void {
    this.submoduleName = this.details.submodule_name;
    this.hotspotImageUrl = this.details.hotspot_image_url;
    this.hotspotArray = this.details.hotspotArray;
  }

  setStyle(x, y, x2, y2) {
    let left = x + ((x2 - x) / 2);
    let top = y + ((y2 - y) / 2);

    return `left: ${left}px;top:${top}px`;
  }
  addHeading(value) {
    if (!(new RegExp('^(?=.*[#])').test(value))) {
      return `### ${value}`
    } else {
      return value
    }
  }

}
