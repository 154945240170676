import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'preview-quiz',
  templateUrl: './quiz.component.html',
  styles: [
  ]
})
export class QuizComponent implements OnInit {
  @Input() details: any;
  selected: number = 0;
  constructor() { }

  ngOnInit(): void {
  }
  addHeading(value) {
    if (!(new RegExp('^(?=.*[#])').test(value))) {
      return `### ${value}`
    } else {
      return value
    }
  }
  checked(sel) {
    this.selected = sel;
  }


}
