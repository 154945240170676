import { Component, Input, OnChanges, OnInit } from '@angular/core';

@Component({
  selector: 'preview-product-listing',
  templateUrl: './product-listing.component.html',
  styles: [
  ]
})
export class ProductListingComponent implements OnInit, OnChanges {
  @Input() details: any;
  constructor() { }
  productList: any = []
  submoduleName: string = "";

  ngOnChanges() {
    this.productList = this.details.productList;
    this.submoduleName = this.details.submodule_name;
  }

  ngOnInit(): void {
    this.productList = this.details.productList;
    this.submoduleName = this.details.submodule_name;
  }
  addHeading(value) {
    if (!(new RegExp('^(?=.*[#])').test(value))) {
      return `### ${value}`
    } else {
      return value
    }
  }

}
