export const callAPIConstants = {
    AdminLogin: 'login',
    AdminRegister: 'admin/register',
    VerifyOTP: 'verifyEmailToken',
    ForgotPassword: 'forgotPassword',
    ResetPassword: 'resetPassword',
    AdminLogout: 'logout',
    AdminGetProfile: 'admin/profile',
    AdminEditProfile: 'admin/editProfile',
    AdminFileUpload: 'admin/fileUpload',
    UpdateTheme: 'admin/updateThemeDetails',
    CountriesListing: 'countriesListing',
    getCountryDetails: 'getCountryDetails',
    getCurrencyDetails: 'getCurrencyDetails',
    getTimezoneDetails: 'getTimezoneDetails',
    getCountriesList: 'getCountriesList',
    addUpdateCountry: 'addUpdateCountry',
    addUpdateCurrency: 'addUpdateCurrency',
    addUpdateTimezone: 'addUpdateTimezone',
    changeCountriesStatus: 'changeCountriesStatus',
    deleteCountries: 'deleteCountries',
    timezoneListing: 'timezoneListing',
    changeTimezoneStatus: 'changeTimezoneStatus',
    deleteTimezones: 'deleteTimezones',
    getTimezoneList: 'getTimezoneList',
    getCurrenciesList: 'getCurrenciesList',
    currenciesListing: 'currenciesListing',
    deleteCurrencies: 'deleteCurrencies',
    changeCurrenciesStatus: 'changeCurrenciesStatus',
    /***** social media sdk *****/
    getsocialMediaSDK: 'getSocialMediaSDK',
    addSocialMediaSDK: 'addSocialMediaSDK',
    /***** social media link *****/
    getSocialMediaLinks: 'getSocialMediaLinks',
    addSocialMediaLinks: 'addSocialMediaLinks',
    /***** global setiing *****/
    addGlobalSettings: 'addGlobalSettings',
    getGlobalSettings: 'getGlobalSettings',
    /***** payment setting *****/
    getPaymentDetails: "getPaymentDetails",
    addPaymentDetails: "addPaymentDetails",
    /***** smtp setting *****/
    addSMTPAndSMSSettings: "addSMTPAndSMSSettings",
    getSMTPAndSMSSettings: "getSMTPAndSMSSettings",
    /***** email notification *****/
    getEmailNotificationSettings: "getEmailNotificationSettings",
    addEmailNotificationSettings: "addEmailNotificationSettings",
    /***** Faq *****/
    FaqsListing: 'faqsListing',
    DeleteFaqs: 'deleteFAQs',
    FaqsDetails: 'getFAQDetails/',
    FaqsAddorUpdate: 'addUpdateFAQ',
    FaqsCategoryListing: 'faqCategoriesListing',
    FaqsStatusChange: 'changeFAQsStatus',
    FaqsSearchList: 'getFAQsList',
    AddUpdateFaqsCategory: 'addUpdateFAQCategory',
    DeleteFaqsCategory: 'deleteFAQCategories',
    FaqsCategoryDetails: 'getFAQCategoryDetails/',
    FaqsCategoryStatus: 'changeFAQCategoriesStatus',
    FaqsCategoriesSearchList: 'getFAQCategoriesList',
    /***** static && Blogs *****/
    Listing: 'listBlog',
    DeleteBlog: 'deleteBlog',
    ChangeStatus: 'changeBlogStatus',
    GetBlogDetails: 'getBlogDetails/',
    AddBlog: 'addUpdateBlog',
    BlogCategoryListing: 'listBlogCategory',
    AddUpdateBlogCategory: 'addUpdateblogCategory',
    GetBlogCategoryDetails: 'getBlogCategoryDetails/',
    BlogCategoryStatusChange: 'changeBlogCategoryStatus',
    DeleteBlogCategory: 'deleteBlogCategory',
    /***** common *****/
    deleteFilter: 'deleteFilter/',
    saveFilter: 'saveFilter',


    /***** Account *****/
    GetAccountDetail: 'myAccount',
    UpdateAccountDetails: 'myAccount',
    ChangePassword: 'changePassword',
    /***** Role APIs *****/
    RolesList: 'roles/getRoleList',
    AddRole: 'roles',
    GetRoleDetail: 'roles/',
    DeleteRole: 'roles/delete',
    RoleChangeStatus: 'roles/changeStatus',
    RoleGetColumnData: 'roles/roleFieldsList',
    RoleGetModuleData: 'roles/roleModuleList',
    RoleGetaddedByColumnData: 'adminListInRoles',
    /***** Team Member *****/
    GetTeamMember: 'teamMembers/',
    AddTeamMember: 'teamMembers',
    TeamMembers: 'teamMembers/getListing',
    TeamChangeStatus: 'teamMembers/changeStatus',
    /***** Groups *****/
    GetGroupsList: 'userGroups/getUserGroupList',
    ChangeGroupStatus: 'userGroups/changeStatus',
    DeleteUserGroup: 'userGroups/delete',
    AddUserGroup: 'userGroups',
    /***** Users *****/
    ImportUsers: 'users/importFrontUsers',
    UserList: 'users/getFrontUsersList',
    UserChangeStatus: 'users/changeStatus',
    UserDeleteUsers: 'users/delete',
    AddUser: 'users',
    GetAllUserGroup: 'users/getAllUserGroup',
    GetAssignCourses: 'users/getTrainingCourseList',
    GetGroupDetailById: 'users/getUserGroupDetailById/',
    SetManagerDetail: 'users/getUserManagerDetailByField',
    GetUserDetail: 'users/getProfile/',
    /***** Training Course APIs *****/
    GetCourse: 'trainingCourse',
    GetCourseById: 'trainingCourse/',
    GetOrderList: 'trainingCourse/getCourseDisplayOrders',
    FileUpload: 'uploadImage',
    MediaUpload: 'uploadMedia',
    AddNewCourse: 'trainingCourse',
    ChangeCourseStatus: 'trainingCourse/changeStatus',
    RequestCourse: 'trainingCourse/requestCourse',
    AddNewModule: 'trainingCourseModule',
    GetModuleById: 'trainingCourseModule/',
    ChangeModuleStatus: 'trainingCourseModule/changeStatus',
    GetModuleList: 'trainingCourseModule/getAllCourseModules/',
    GetAllCoursesModulesList: 'trainingCourseModule/getAllCourseModulesList',
    CopyPasteModule: 'trainingCourseModule/copyPasteModule',
    DuplicateModule: 'trainingCourseModule/duplicateModule/',
    DuplicateCourse: 'trainingCourse/duplicateCourse/',
    /***** Submodule APIs *****/
    GetSubmoduleTypes: 'trainingCourseSubmodule/getSubModuleTypes',
    GetAllSubmodulesList: 'trainingCourseSubmodule/getAllCourseSubModules/',
    AddSubmodule: 'trainingCourseSubmodule',
    GetSubmoduleById: 'trainingCourseSubmodule/',
    CopypasteSubmoduleById: 'trainingCourseSubmodule/copyPasteSubModule',
    DuplicateSubmoduleById: 'trainingCourseSubmodule/subModulesDuplicate/',
    ChangeSubmoduleStatus: 'trainingCourseSubmodule/changeStatus',
    GetSubModuleTypes: "trainingCourseSubmodule/getSubModuleTypes",
    /***** Question Bank APIs *****/
    QuestionCategories: 'trainingCourseSubmodule/quiz/categories/getListing',
    AddCategory: 'trainingCourseSubmodule/quiz/categories',
    GetCategoryById: 'trainingCourseSubmodule/quiz/categories/',
    GetCategoryQuestionsById: 'trainingCourseSubmodule/quiz/categories/listQuestions/',
    StoreCategoryQuestionsById: 'trainingCourseSubmodule/quiz/categories/storeQuestions',
    ChangeCategoryStatus: 'trainingCourseSubmodule/quiz/categories/changeStatus',
    ExportQuestionBank: 'trainingCourseSubmodule/quiz/questionBank/export/',
    ImportQuestionBank: 'trainingCourseSubmodule/quiz/questionBank/import',
    GetQuizCategories: 'trainingCourseSubmodule/getQuizCategories/',
    /***** View Response APIs *****/
    GetPocResponses: 'trainingCourseSubmodule/pocResponses/getListing',
    GetPocUserResponses: 'trainingCourseSubmodule/pocResponses/userResponse/getListing',
    ChangePocResponseStatus: 'trainingCourseSubmodule/pocResponses/region/changeStatus',
    AddEditPocResponseComment: 'trainingCourseSubmodule/pocResponses/region/comment',
    AddPocResponse: 'trainingCourseSubmodule/pocResponses/userResponse',
    GetViewResponseCourseFeedbackList: 'trainingCourseSubmodule/feedback/user/getListing',
    GetViewResponseCourseFeedbackDetail: 'trainingCourseSubmodule/feedback/',
    GetViewResponseJobList: 'jobs/getListing',
    GetViewResponseJobDetail: 'jobs',
    GetViewResponseQuizList: 'trainingCourseSubmodule/quiz/result/getListing',
    MarkAsPassed: 'trainingCourseSubmodule/quiz/result/markAsPass/',
    /***** Get Resources List *****/
    GetResourceList: 'resources/getListing',
    GetResourceById: 'resources/',
    ChangeResourceStatus: 'resources/changeStatus',
    AddResource: 'resources',
    DeleteResource: 'resources/delete',
    /***** Get Products List *****/
    GetProductsList: 'products/getListing',
    /***** Get News List *****/
    GetNewsList: 'news/getListing',

    /***** Notifications APIs *****/
    NotificationList: 'notifications',
    GetTodayNotifications: 'webNotifications/todayWebNotifications',
    GetPreviousNotifications: 'webNotifications/previousWebNotifications',
    MarkAsRead: 'webNotifications/markasread',
    GetNotificationCount: 'webNotifications/notificationCount'

};

