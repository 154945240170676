<div class="preview-section jobs-preview">
    <markdown class="variable-binding" [data]="addHeading(submoduleName)"></markdown>
    <div class="preview-content" style="height: 86%; overflow: auto;">
        <div class="form-group">
            <label class="label-small"><strong>Upload Photos</strong></label>
            <div class="file-uploads" style="justify-content: flex-start;">
                <ng-container *ngIf="jobArr">
                    <div *ngFor="let job of jobArr; let i =index" class="upload-item">
                        <label class="filelabel">
                            <em class='bx bx-plus-circle'></em>
                            <input class="fileuploadinout" id="fileinput1{{i}}" name="upload_photos{{i}}" type="file" />
                        </label>
                        <h6 *ngIf="job.upload_type=='image'" class="upload-file-name"><em class="bx bx-image"></em>
                            Image
                        </h6>
                        <h6 *ngIf="job.upload_type=='Video'" class="upload-file-name"><em class="bx bxl-youtube"></em>
                            Video
                        </h6>
                    </div>
                </ng-container>
            </div>
        </div>
        <div class="form-group">
            <label class="label-small color-red">Job Number</label>
            <input type="text" class="form-control secondary-control" />
        </div>
        <div class="form-group">
            <label class="label-small color-red">Date & Time Completed</label>
            <div class="with-icons">
                <input type="text" class="form-control secondary-control" bsDatepicker />
                <em class='bx bx-calendar'></em>
            </div>
        </div>
        <div class="form-group">
            <label class="label-small color-red">Select Training Course</label>
            <ng-select class="sm-ng-select" [items]="course" bindLabel="name" placeholder="CSP & Inside/ Out Cable"
                [(ngModel)]="course" [clearable]="false">
            </ng-select>
        </div>
        <div class="form-group">
            <label class="label-small color-red">Add Location</label>
            <div class="with-icons">
                <input type="text" class="form-control secondary-control" />
                <em class='bx bx-current-location'></em>
            </div>
        </div>
        <div class="form-group">
            <label class="label-small color-red">Add Comments (Optional)</label>
            <textarea class="form-control secondary-control"></textarea>
        </div>
        <div class="form-group">
            <label class="filelabel with-text">
                <em class='bx bx-plus-circle'></em>
                <input class="fileuploadinout" id="fileinput4" name="upload_photos2" type="file" />
                <span class="upload-text">Do you want to upload an additional Photo / Video? (Optional)</span>
            </label>
        </div>
    </div>
</div>