<div class="preview-section image-hotspot-preview">
    <markdown class="variable-binding" [data]="addHeading(submoduleName)"></markdown>
    <div class="preview-content">
        <img [src]="hotspotImageUrl" alt="">
        <div class="hotspot-select-box">
            <ng-container *ngFor="let hotspot of hotspotArray; let i=index">
                <div class="select-box" style="position: absolute"
                    [style]="setStyle(hotspot.position.x, hotspot.position.y,hotspot.position.x2,hotspot.position.y2)">
                    <span>{{i+1}}</span>
                </div>
            </ng-container>
        </div>
    </div>
</div>