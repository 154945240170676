import { Injectable } from '@angular/core';
import 'rxjs/add/operator/map';
import { messages } from '../constants/errorMessagesData';


@Injectable()
export class ErrorMessages {
  public MSG = (messages as any);

  constructor() { }
  /*****************************************************************************************
  @PURPOSE      : To Show Error on Form validations
  @PARAMETERS   : field, error
  @RETURN       : message
  /*****************************************************************************************/
  getError(field, error) {
    let message = '';
    if (error) {
      if (error.required) {
        const required = this.MSG.ERROR.REQUIRED;
        switch (field) {
          case 'email': { message = required.email; } break;
          case 'password': { message = required.Password; } break;
          case 'code': { message = required.code; } break;
          case 'confirmPassword': { message = required.confirmPassword; } break;
          case 'newPassword': { message = required.newPassword; } break;
          case 'oldPassword': { message = required.oldPassword; } break;
          /*************  Email Templates ******************/
          case 'emailKey': { message = required.emailKey; } break;
          case 'subject': { message = required.subject; } break;
          /*************  Team Member ******************/
          case 'teamName': { message = required.teamName; } break;
          case 'uniqueId': { message = required.uniqueId; } break;
          case 'role': { message = required.role; } break;
          /*************  Courses ******************/
          case 'title': { message = required.title } break;
          case 'description': { message = required.description } break;
          case 'display_order': { message = required.display_order } break;
          case 'keywords': { message = required.keywords } break;
          case 'schedule_at': { message = required.schedule_at } break;
          case 'moduleTitle': { message = required.moduleTitle } break;
          case 'courseDate': { message = required.courseDate; } break;
          case 'displayOrder': { message = required.displayOrder; } break;
          /*************  Submodules ******************/
          case 'submodule_name': { message = required.submodule_name; } break;
          case 'info': { message = required.info; } break;
          case 'subtitle': { message = required.subtitle; } break;
          case 'button_text': { message = required.button_text; } break;
          case 'duration': { message = required.duration; } break;
          case 'unlock_datetime': { message = required.unlock_datetime; } break;
          case 'completion_percentage': { message = required.completion_percentage; } break;
          case 'time_spent': { message = required.time_spent; } break;
          case 'url_360': { message = required.url_360; } break;
          case 'condition': { message = required.condition; } break;
          case 'touch_count': { message = required.touch_count; } break;
          case 'productName': { message = required.productName } break;
          case 'youtube_id': { message = required.youtube_id } break;
          case 'vimeo_id': { message = required.vimeo_id } break;
          case 'start_time': { message = required.start_time } break;
          case 'end_time': { message = required.end_time } break;
          case 'sourceTitle': { message = required.sourceTitle } break;
          case 'videoTitle': { message = required.videoTitle; } break;
          case 'stepTitle': { message = required.stepTitle; } break;
          case 'button1_text': { message = required.button1_text; } break;
          case 'button2_text': { message = required.button2_text; } break;
          case 'caption': { message = required.caption; } break;
          case 'job_no_required': { message = required.job_no_required; } break;
          case 'job_topic': { message = required.job_topic; } break;
          case 'quiz_duration': { message = required.quiz_duration; } break;
          case 'custom_quiz_duration': { message = required.custom_quiz_duration; } break;
          case 'passing_criteria': { message = required.passing_criteria; } break;
          case 'failed_attempts': { message = required.failed_attempts; } break;
          case 'category_name': { message = required.category_name; } break;
          case 'comment': { message = required.comment; } break;
          case 'ratings': { message = required.ratings; } break;
          case 'question': { message = required.question } break;
          case 'answer': { message = required.answer } break;
          case 'answer_explanation': { message = required.answer_explanation; } break;

          /*************  Account ******************/
          case 'contact_no': { message = required.contact_no; } break;
          case 'website': { message = required.website; } break;
          case 'latitude': { message = required.latitude; } break;
          case 'longitude': { message = required.longitude; } break;
          case 'unique_id_name': { message = required.unique_id_name; } break;
          /*************  User Groups ******************/
          case 'unique_id': { message = required.unique_id; } break;
          case 'manager_email': { message = required.manager_email; } break;
          case 'groupName': { message = required.groupName; } break;
          case 'emailId': { message = required.emailId; } break;
          /*************  Manage Resource ******************/
          case 'tags': { message = required.tags; } break;

          case 'username': { message = required.Username; } break;
          case 'firstName': { message = required.firstName; } break;
          case 'lastName': { message = required.lastName; } break;
          case 'userName': { message = required.userName; } break;
          case 'mobile': { message = required.mobile; } break;
          case 'oldPassword': { message = required.currentPassword; } break;
          case 'amount': { message = required.Amount; } break;
          case 'newPassword': { message = required.newPassword; } break;
          case 'firstname': { message = required.firstname; } break;
          case 'lastname': { message = required.lastname; } break;
          case 'phoneNo': { message = required.PhoneNo; } break;
          case 'roleName': { message = required.roleName; } break;
          case 'role': { message = required.role; } break;
          case 'filterName': { message = required.filterName; } break;

          case 'tag': { message = required.tag; } break;
          case 'category': { message = required.category } break;
          case 'categoryName': { message = required.categoryName } break;
          case 'faqsCategory': { message = required.faqsCategory } break;
          case 'blogTitle': { message = required.blogTitle } break;
          case 'slug': { message = required.slug } break;

          case 'host': { message = required.host; } break;
          case 'port': { message = required.port; } break;
          case 'defaultFromEmail': { message = required.defaultFromEmail; } break;
          case 'metaTitle': { message = required.metaTitle; } break;
          case 'metaDescription': { message = required.metaDescription; } break;
          case 'metaKeyword': { message = required.metaKeyword; } break;
          case 'pageTitle': { message = required.pageTitle; } break;
          case 'currency': { message = required.currency; } break;
          case 'dateFormat': { message = required.dateFormat; } break;
          case 'defaultAdminEmail': { message = required.defaultAdminEmail; } break;
          case 'templateName': { message = required.templateName; } break;
          case 'countryName': { message = required.countryName; } break;
          case 'countryId': { message = required.countryId; } break;
          case 'countryCode': { message = required.countryCode; } break;
          case 'companyName': { message = required.companyName; } break;
          case 'googleToken': { message = required.googleToken; } break;
          case 'facebookAppId': { message = required.facebookAppId; } break;
          case 'pKey': { message = required.pKey; } break;
          case 'sKey': { message = required.sKey; } break;
          case 'mode': { message = required.mode; } break;
          case 'clientId': { message = required.clientId; } break;
          case 'clientSecret': { message = required.clientSecret; } break;
          case 'authToken': { message = required.authToken; } break;
          case 'accountSid': { message = required.accountSid; } break;
          case 'phoneCode': { message = required.phoneCode; } break;
          case 'mainCurrency': { message = required.mainCurrency; } break;
          case 'timezone': { message = required.timezone; } break;
          case 'twitterLink': { message = required.twitterLink; } break;
          case 'facebookLink': { message = required.facebookLink; } break;
          case 'googleLink': { message = required.googleLink; } break;
          case 'linkedinLink': { message = required.linkedinLink; } break;
          case 'instagramLink': { message = required.instagramLink; } break;
          case 'websiteLink': { message = required.websiteLink; } break;
          case 'dob': { message = required.dob; } break;
          case 'country': { message = required.country; } break;
          case 'languages': { message = required.languages; } break;
          case 'address': { message = required.address; } break;
          case 'headerSnippet': { message = required.headerSnippet; } break;
          case 'footerSnippet': { message = required.footerSnippet; } break;
          case 'analyticsSnippet': { message = required.analyticsSnippet; } break;
          case 'authToken': { message = required.authToken; } break;
          case 'accountSid': { message = required.accountSid; } break;
          case 'image': { message = required.image; } break;
        }
      } else if (error.pattern) {
        const pattern = this.MSG.ERROR.PATTERN;
        switch (field) {
          case 'email': { message = pattern.Email; } break;
          case 'password': { message = pattern.Password; } break;
          case 'latitude': { message = pattern.latitude; } break;
          case 'longitude': { message = pattern.longitude; } break;
          case 'url_360': { message = pattern.url_360; } break;
          case 'custom_quiz_duration': { message = pattern.custom_quiz_duration; } break;
          case 'passing_criteria': { message = pattern.passing_criteria; } break;
          case 'failed_attempts': { message = pattern.failed_attempts; } break;

          case 'website': { message = pattern.website; } break;
          case 'manager_email': { message = pattern.manager_email; } break;

          case 'completion_percentage': { message = pattern.completion_percentage; } break;

          case 'newPassword': { message = pattern.newPassword; } break;
          case 'confirmPassword': { message = pattern.confirmPassword; } break;
          case 'adminEmail': { message = pattern.adminEmail; } break;
          case 'fromEmailId': { message = pattern.fromEmailId; } break;
          case 'defaultFromEmail': { message = pattern.Email; } break;
          case 'defaultAdminEmail': { message = pattern.Email; } break;
          case 'link': { message = pattern.link; } break;
        }
      } else if (error.minlength) {
        const minlength = this.MSG.ERROR.MINLENGTH;
        switch (field) {
          case 'code': { message = minlength.code; } break;
          case 'unique_id': { message = minlength.unique_id; } break;
          case 'answer': { message = minlength.answer; } break;
          case 'question': { message = minlength.question; } break;
          case 'description': { message = minlength.description; } break;
          case 'mobile': { message = minlength.PhoneNo; } break;
          case 'confirmPassword': { message = minlength.confirmPassword; } break;
          case 'newPassword': { message = minlength.newPassword; } break;
          case 'port': { message = minlength.port; } break;
        }
      } else if (error.maxlength) {
        const maxlength = this.MSG.ERROR.MAXLENGTH;
        switch (field) {
          case 'job_no_required': { message = maxlength.job_no_required; } break;

        }
      }
      return message;
    }
  }
  /*****************************************************************************************/
}
