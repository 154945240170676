export const messages = {
    ERROR: {
        REQUIRED: {
            email: 'Enter Email Address',
            Password: 'Enter Password',
            code: 'Enter Code',
            confirmPassword: 'Enter Confirm Password',
            newPassword: 'Enter New Password',
            oldPassword: 'Enter Old Password',
            /***** Email Templates *****/
            emailKey: 'Enter Title',
            subject: 'Enter Subject',
            /***** Team Member *****/
            teamName: 'Enter Name',
            uniqueId: 'Enter Unique ID',
            role: 'Select Role',
            /***** Courses *****/
            courseDate: 'Select Course Date',
            displayOrder: 'Select Vendor',
            /***** Submodules *****/
            submodule_name: 'Enter Submodule Name',
            info: 'Enter Info',
            subtitle: 'Enter Subtitle',
            button_text: 'Enter Button Text',
            duration: 'Enter Duration',
            unlock_datetime: 'Enter Unlock Date & Time',
            completion_percentage: 'Enter Submodule Completion',
            time_spent: 'Enter Time Spent',
            url_360: 'Enter URL',
            touch_count: 'Enter Touch Counts',
            condition: 'Select Condition',
            youtube_id: 'Enter Youtube Id',
            vimeo_id: 'Enter Vimeo Id',
            start_time: 'Enter Start Time',
            end_time: 'Enter End Time',
            sourceTitle: 'Enter Title',
            videoTitle: 'Enter Video Title',
            stepTitle: 'Enter Step Title',
            button1_text: 'Enter Button Text 1',
            button2_text: 'Enter Button Text 2',
            caption: 'Enter Caption',
            job_no_required: 'Enter No. of uploads required',
            job_topic: 'Enter Job Topic',
            quiz_duration: 'Select Duration of Quiz',
            custom_quiz_duration: 'Enter Custom Duration',
            passing_criteria: 'Enter Passing Criteria',
            failed_attempts: 'Enter No. of failed attempts',
            category_name: 'Enter Category',
            comment: 'Enter General Comment',
            ratings: 'Enter Ratings',
            answer: 'Enter Option',
            question: 'Enter Question',
            answer_explanation: 'Enter Answer Explanation',
            /***** Account *****/
            contact_no: 'Enter Contact No',
            website: 'Enter Website',
            address: 'Enter Address',
            latitude: 'Enter Latitude',
            longitude: 'Enter Longitude',
            unique_id_name: 'Enter Unique ID',
            /***** Users Groups *****/
            manager_email: "Enter Manager's Email",
            unique_id: 'Enter Unique ID',
            groupName: 'Enter Group Name',
            emailId: 'Enter Email',
            /***** Reources *****/
            tags: 'Enter Resource Tags',

            Email: 'Enter Email Address',
            Username: 'Enter Username',
            currentPassword: 'Enter Current Password',
            firstname: 'Enter First Name',
            lastname: 'Enter Last Name',
            firstName: 'Enter First Name',
            lastName: 'Enter Last Name',
            userName: 'Enter User Name',
            companyName: 'Enter Company Name',
            mobile: 'Enter Mobile Number',
            roleName: 'Enter Role',
            PhoneNo: ' Enter Phone Number',
            Amount: ' Enter Amount',
            filterName: 'Enter Name of Filter',
            fromEmailId: 'Please enter email',
            tag: 'please add tag',
            host: 'Enter Host',
            port: 'Enter Port',
            category: 'Enter Blog Category',
            categoryName: 'Select Category',
            faqsCategory: 'Enter Category',
            image: 'select Image',
            /***** Course err keys *****/
            title: 'Enter Course Title',
            description: 'Enter Description',
            display_order: 'Enter Display Order',
            keywords: 'Enter Keywords',
            schedule_at: 'Pick Date & Time',
            moduleTitle: 'Enter Module Title',
            productName: 'Enter Product Name',
            productCode: 'Enter Code',

            blogTitle: 'Enter Blog Title',
            slug: 'Enter Slug',
            defaultFromEmail: 'Enter Default From EMail',
            defaultAdminEmail: 'Enter Default Admin Email',
            metaTitle: 'Enter Meta Title',
            metaDescription: 'Enter Meta Description',
            metaKeyword: 'Enter Meta Keyword',
            pageTitle: 'Enter Page Title',
            currency: 'Select Currency',
            mainCurrency: 'Enter Currency',
            dateFormat: 'Select Date Format',
            templateName: 'Enter Template Name',
            countryName: 'Enter Country Name',
            countryId: 'Enter Country Name',
            countryCode: 'Enter Country Code',
            phoneCode: 'Enter Phone Code',
            facebookAppId: 'Enter Facebook App Id',
            googleToken: 'Enter Google OAuth Token',
            pKey: 'Enter Publishable Key',
            sKey: 'Enter Secret Key',
            mode: 'Enter Mode',
            clientSecret: 'Enter Client Secret',
            clientId: 'Enter Client Id',
            timezone: 'Enter Timezone',
            twitterLink: 'Enter Twitter Link',
            facebookLink: 'Enter Facebook Link',
            googleLink: 'Enter Google Link',
            linkedinLink: 'Enter Linkedin Link',
            instagramLink: 'Enter Instagram Link',
            websiteLink: 'Enter Website Link',
            dob: 'Select Date Of Birth',
            country: 'Select Country',
            languages: 'Select language',
            footerSnippet: 'Enter Footer Snippet',
            headerSnippet: 'Enter Header Snippet',
            analyticsSnippet: 'Enter Analytics Snippet',
            authToken: 'Enter Auth Token',
            accountSid: 'Enter Account SID',
        },
        PATTERN: {
            Email: 'Enter Valid Email Address',
            manager_email: 'Enter Valid Manager Email',
            latitude: 'Enter Valid latitude',
            longitude: 'Enter Valid longitude',
            website: 'Website should start with https:// or http:// or www.',
            url_360: 'Enter URL starts with https:// or http:// or www. and ends with .html or .htm',
            completion_percentage: 'Submodule Completion should be 1-100(inclusive)',
            Password: 'Password must contain 8-20 digits with at least 1 uppercase, 1 lowercase and 1 special charater',
            Contactno: 'Invalid Contact Number',
            emailId: 'Enter Valid Email',
            fromEmailId: 'Enter valid Email',
            adminEmail: 'Enter valid Email',
            newPassword: 'Password must contain 8-20 digits with at least 1 uppercase, 1 lowercase and 1 special charater',
            link: 'url should contain https://',
            custom_quiz_duration: 'Custom Duration should be 1-240 mins(inclusive)',
            passing_criteria: 'Passing Criteria should be 1-100(inclusive)',
            failed_attempts: 'Failed attempts should be 1-10(inclusive)',
        },
        MINLENGTH: {
            contact_no: 'Contact Number should be 10 digits',
            code: 'Code must contain 6 digits',
            unique_id: 'Unique ID should have 6 digits',
            answer: 'Answer must be minimum 50 characters.',
            question: 'Question must be minimum 10 characters.',
            description: 'Blog description should be minimum 20 characters',
            category: 'Blog category should be minimum 2 characters',
            PhoneNo: 'Mobile Number should be 10 digits',
            confirmPassword: 'Require atleast 6 characters',
            newPassword: 'Require atleast 6 characters',
            port: 'Minimum 3 digit required',
        },
        MAXLENGTH: {
            job_no_required: 'No of uploads field will allow only 2 digits'
        },
        CUSTOM: {
            ConfirmPassword: 'Confirm password does not match!',
        },
    },
};
