<div class="preview-section confirmation-preview-section">
    <markdown class="variable-binding" [data]="addHeading(submoduleName)"></markdown>
    <div class="preview-content" style="height: 86%; overflow: auto;">
        <ng-container *ngIf="confirmationList">
            <div *ngFor="let confirmation of confirmationList" class="confirmation-item-box">
                <div class="confirmation-img">
                    <a href="javascript:;">
                        <img [src]="confirmation.image1" alt="Course Image">
                    </a>
                </div>
                <div class="confirmation-detail">
                    <h6>
                        <a href="javascript:;">
                            <markdown class="variable-binding" [data]="confirmation.title"></markdown>
                        </a>
                    </h6>
                </div>
                <div class="info-over">
                    <div class="custom-checkbox sm">
                        <label for="option1" class="pl-3 mt-1">
                            <input type="checkbox" id="option1" checked />
                            <span></span>
                        </label>
                    </div>
                </div>
            </div>
        </ng-container>
    </div>
</div>