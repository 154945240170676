<div class="preview-section video-guide-preview">
    <h3 style="display: inline-flex;"><a style="margin-top:13%" *ngIf="detailScreen" (click)="detailScreen = false;"
            href="javascript:;"><em class="bx bx-arrow-back"></em></a>
        <markdown class="variable-binding" [data]="addHeading(submoduleName)"></markdown>
    </h3>
    <div class="preview-content" style="height: 86%; overflow: auto;">
        <div *ngIf="!detailScreen" class="happy-unhappy-listing">
            <div class="happy-unhappy-section">
                <div *ngFor="let video of videosArray; let i=index" (click)="navigateToDetail(i)"
                    class="course-item-box course-small">
                    <div *ngIf="video.resource_type==='source'" class="course-img">
                        <a href="javascript:;" style="position: relative;">
                            <img [src]="video.resource_thumbnail_url" alt="Module">
                            <em style="position: absolute;font-size: 30px;color: white;left: 26%;top: 0%;"
                                class="bx bx-play-circle"></em>
                        </a>
                    </div>
                    <div *ngIf="video.resource_type==='youtube'||video.resource_type==='vimeo'" class="course-img">
                        <a href="javascript:;" style="position: relative;">
                            <img [src]="video.resource_thumbnail" alt="Module">
                            <em style="position: absolute;font-size: 30px;color: white;left: 26%;top: 0%;"
                                class="bx bx-play-circle"></em>
                        </a>
                    </div>
                    <div class="course-detail-box">
                        <h6><a href="javascript:;">
                                <markdown class="variable-binding" [data]="video.title"></markdown>
                            </a></h6>

                        <div class="course-info">
                            <progressbar style="width: 80%;" [value]="50"><span class="progress-value">50%</span>
                            </progressbar>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <ng-container *ngIf="detailScreen">

            <img *ngIf="videosArray[currentIndex].resource_type==='source'"
                [src]="videosArray[currentIndex].resource_thumbnail_url" style="width: 100%;" alt="Video Guides">
            <img *ngIf="videosArray[currentIndex].resource_type==='youtube'||videosArray[currentIndex].resource_type==='vimeo'"
                [src]="videosArray[currentIndex].resource_thumbnail" style="width: 100%;" alt="Video Guides">
            <div class="content-box">
                <h5>Steps</h5>
                <div *ngFor="let step of videosArray[currentIndex].steps" class="video-item">
                    <div class="title-box" style="flex-wrap: inherit;">
                        <markdown class="variable-binding" [data]="step.title"></markdown>
                        <a href="javascript:;"><em class="bx bx-play-circle"></em></a>
                    </div>
                    <div [innerHtml]="step.description">
                    </div>
                </div>
            </div>
        </ng-container>
    </div>
</div>