<div class="preview-section image-gallery-preview">
    <markdown class="variable-binding" [data]="addHeading(submoduleName)"></markdown>
    <div class="preview-content" style="height: 86%; overflow: auto;">
        <ngx-slick-carousel *ngIf="slides?.length>0" class="carousel" #slickModal="slick-carousel"
            [config]="slideConfig">
            <div ngxSlickItem *ngFor="let slide of slides" class="slide">
                <div class="image-gallery">
                    <img width="100%" [src]="slide.image1" alt="Course Image">
                </div>
            </div>
        </ngx-slick-carousel>
    </div>
</div>