<section class="login-wrapper" style="background-image:url(assets/images/login-bg.jpg);">
    <div class="login-body">
        <div class="login-container">
            <div class="logo-box">
                <a href="javascript:;">
                    <img src="assets/images/logo.svg" alt="Logo">
                </a>
            </div>
            <!-- <div class="login-content">
                <h1>Sign In</h1>
                <p>New to Our Product? <a href="javascript:;" class="text-link-btn text-primary">Create an Account</a>
                </p>
            </div> -->
            <div class="login-form">
                <form (ngSubmit)="onLogin(loginForm, user)" #loginForm="ngForm" autocomplete="off">
                    <div class="form-group">
                        <label for="email">Email</label>
                        <input type="text" name="email" pattern="^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$"
                            id="email" placeholder="Enter Email Address" class="form-control"
                            [ngClass]="((submitted || email.touched) && email.invalid)?'has-danger':''"
                            [(ngModel)]="user.email" required #email="ngModel">
                        <div *ngIf="(submitted || email.touched) && email.invalid" class="has-error">
                            {{showErrorService.showError('email', email.errors)}}
                        </div>
                    </div>
                    <div class="form-group">
                        <label for="password">Password</label>
                        <input [type]="passwordType ? 'text' : 'password'" name="password" id="password"
                            placeholder="Enter Password" class="form-control"
                            [ngClass]="((submitted || password.touched) && password.invalid)?'has-danger':''"
                            [(ngModel)]="user.password" required #password="ngModel" />
                        <span [tooltip]="passwordType ? 'Hide Password' : 'Show Password'" toggle="#password-field"
                            class="bx bx-hide field-icon toggle-password" [ngClass]="{
                            'bx-bx-hide': !passwordType,
                            'bx-show': passwordType
                          }" (click)="toggleFieldTextType()"></span>
                        <div *ngIf="(submitted || password.touched) && password.invalid" class="has-error">
                            {{showErrorService.showError('password', password.errors)}}
                        </div>
                    </div>
                    <div class="form-group">
                        <div class="custom-checkbox">
                            <label for="keeplogin" class="mb-0">
                                <input type="checkbox" name="keeplogin" id="keeplogin" [(ngModel)]="user.keeplogin">
                                <span></span>Keep me signed in
                            </label>
                        </div>
                    </div>
                    <div class="login-btn">
                        <button class="btn btn-primary btn-block" type="submit">Sign In</button>
                    </div>
                    <div class="forgot-password">
                        <a href="javascript:;" class="text-link-btn text-primary"
                            [routerLink]="[URLConstants.FORGOT_PASS]">Forgot your password?</a>
                    </div>
                </form>
            </div>
        </div>
    </div>
</section>