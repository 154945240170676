<div class="preview-section photo-hotspot-uploader-preview">
    <markdown class="variable-binding" [data]="addHeading(submoduleName)"></markdown>
    <div class="preview-content">
        <img [src]="previewData.hotspot_photo_url" alt="Hotspot Image" usemap="#workmap">
        <div class="hotspot-select-box">
            <ng-container *ngFor="let region of previewData.regionArray; let i = index;">
                <div class="select-box" style="position: absolute"
                    [style]="setStyle(region.position.x, region.position.y,region.position.x2, region.position.y2)">
                    <span>{{i+1}}</span>
                </div>
            </ng-container>
        </div>
    </div>
</div>